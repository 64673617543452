import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useResource,
  usePositions,
  useNavigation,
  useLoaders,
  useAlerts
} from 'hooks'
import { validateCategory } from 'helpers/validations/category'

const useCategories = ({ id, ...props }) => {

  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    resourceId: categoryId,
    isLoading,
    isLoaded,
    isEditing,
    isValid,
    resource: category,
    resources: categories,
    fetch: fetchCategory,
    fetchAll: fetchCategories,
    update: updateCategory,
    create: createCategory,
    save: saveCategory,
    destroy: deleteCategory,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    refresh: refreshCategory,
    refreshAll: refreshCategorys,
  } = useResource({
    id: id,
    url: '/api/v1/app/categories',
    resourceName: 'category',
    validateFn: validateCategory
  })


  return {
    isLoading,
    isLoaded,
    isEditing,
    isValid,
    uploadFile,
    deleteFile,
    category,
    categories,
    fetchCategory,
    fetchCategories,
    saveCategory,
    updateCategory,
    createCategory,
    handleChange,
    handleChangePage,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    refreshCategory,
    refreshCategorys
  }
}

export default useCategories
