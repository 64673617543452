import React, { Fragment } from 'react'
import {
  Box,
  Avatar,
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'
import styled from 'styled-components'

const StyledListItem = styled(ListItem)`
  margin-top: 30px;
`

const StyledAvatar = styled(Avatar)`
  height: 60px;
  width: 60px;
  margin-right: 20px;
  font-size: 28px;
  background-color: ${indigo[700]};
`

const Step = ({ step, handleClick }) => (
  <StyledListItem>
    <ListItemIcon>
      <StyledAvatar>
        { step.index }
      </StyledAvatar>
    </ListItemIcon>
    <ListItemText
      primary={ step.title  }
      secondary={
        <Typography variant='body2'>
          { step.description }
        </Typography>
      }
      />
    </StyledListItem>
)

export default Step
