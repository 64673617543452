import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import NoImage from 'resources/images/noimage.png'
import indigo from '@material-ui/core/colors/indigo'
import styled from 'styled-components'

const StyledAvatar = styled(Avatar)`
  width: ${(props => `${props.size}px;`)}
  height: ${(props => `${props.size}px;`)}
`

const UserAvatar = ({ imageUrl, size=40 }) => (
   <StyledAvatar
    size={ size }
    src={ imageUrl ? imageUrl : NoImage }
  />
)

export default UserAvatar
