import {
  isEmpty
} from './validate'

export const validateCompany = (company) => {
  let isValid = true
  let messages = []

  if(isEmpty(company.name)){
    isValid = false
    messages.push('Name is required')
  }

  let validation = {
    isValid,
    messages
  }

  return  validation
}
