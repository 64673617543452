import React, { useState } from 'react'
import {
  TextField,
  FormControl,
  Typography,
  IconButton,
  InputAdornment
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { syntheticEvent } from 'helpers/utils'

const styles = {
  input: {
    backgroundColor: '#fff',
    opacity: '1.0',
    borderRadius: '4px'
  },
  IconButton: {
    padding: '5px'
  },
  icon: {
    fontSize: '16px',
    color: '#888'
  }
}


const TextInput = ({
    style,
    required,
    label,
    type,
    name,
    value,
    multiline,
    handleChange,
    rows,
    placeholder,
    onKeyPress,
    autoComplete='off',
    isClearable,
    ...props
  }) => {

  const [error, setError] = useState(false)

  const handleClearClick = () => {
    let ev = syntheticEvent('', name)
    handleChange(ev)
  }

  const handleInputChange = (ev) => {
    let { name, value } = ev.target
    required && value === "" ?  setError(true) : setError(false)
    handleChange(ev)
  }

  return(
    <FormControl fullWidth style={ style }>
      <Typography variant="overline">
        { label } { label && required && '*' }
      </Typography>
      <TextField
        error={ error }
        rows={ rows }
        style={ styles.input }
        multiline={ multiline }
        autoComplete={ autoComplete }
        fullWidth
        type={ type }
        name={ name }
        placeholder={ placeholder }
        margin="dense"
        onKeyPress={ onKeyPress }
        variant="outlined"
        onChange={ handleInputChange }
        value={ value }
        InputProps={{
          endAdornment:
            isClearable ?
            <InputAdornment position="end">
              { value &&
              <IconButton
                style={ styles.iconButton }
                onClick={ handleClearClick }>
                <Clear style={ styles.icon } />
              </IconButton>
              }
            </InputAdornment>
            :
            null
        }}
      />
    </FormControl>
  )
}

export default TextInput
