import React from 'react'
import {
  Grid,
  Container,
  Box
} from '@material-ui/core'
import styled from 'styled-components'

const drawerWidth = 260;

const StyledContainer = styled(Container)`
  min-height: 650px;
`

const LayoutContainer = ({ maxWidth='xl', children }) => {
  return(
  <StyledContainer maxWidth={ maxWidth }>
    { children && children }
  </StyledContainer>
)}

export default LayoutContainer
