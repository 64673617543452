import React from 'react'
import {
  Box,
  Button,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 20,
    width: 20
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const MenuItem = ({
  handleClick,
  indent,
  value,
  depth=0,
  open,
  expandable=false,
  text,
  icon: Icon }) => {

  const classes = useStyles();

  let paddingLeft = 18;

  if (depth > 0) {
    paddingLeft = 42 + 8 * depth;
  }

  const style = {
    paddingLeft
  };

  return(
    <ListItem
      className={classes.itemLeaf}
      disableGutters>
      <Button
        onClick={(e) => handleClick(value, e)}
        className={clsx(classes.button, `depth-${depth}`)}
        style={style}
      >
      {Icon && <Icon className={classes.icon} />}
      { text }
      { expandable &&
        <>
          {open ? (
            <ExpandLess
              className={classes.expandIcon}
              color="inherit"
            />
          ) : (
            <ExpandMore
              className={classes.expandIcon}
              color="inherit"
            />
          )}
        </>
      }
      </Button>
    </ListItem>
  )
}
export default MenuItem
