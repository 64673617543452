import React from 'react'
import {
  Typography,
  Grid,
  Paper
} from '@material-ui/core'
import { Stat } from 'components'

const DashboardValue = ({label, value, sm=12, md=6, lg=3}) => (
    <Grid item xs={12} sm={sm} md={md} lg={lg}>
      <Stat label={label} value={value} />
    </Grid>

)

export default DashboardValue
