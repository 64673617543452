import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  IconButton
} from '@material-ui/core'
import styled from 'styled-components'
import { InsertDriveFile } from '@material-ui/icons'
import { indigo } from '@material-ui/core/colors'

const StyledDropzone = styled(Dropzone)`
  margin: 0;
  padding: 38px 20px;
  border: 1px dashed #333;
  color: #555;
  height: 100px;
  background-color: #fff;
  text-align: center;
  margin: 0 0 10px 0;
  border-radius: 4px;
`

const styles = {
  icon: {
    height: '50px',
    width: '50px',
    color: indigo[700]
  },
  button: {
    fontSize: '12px'
  }
}

const FileUploader = ({
    fileUrl,
    handleUpload,
    handleDelete,
    ...props
  }) => {

  const [isEditing, setIsEditing] = useState(false)
  const [file, setFile] = useState()
  const [files, setFiles] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const [dropzoneActive, setDropzoneActive] = useState(false)

  const handleEditClick = () => setIsEditing(!isEditing)

  const onDrop = async (files) => {
    setIsUploading(true)
    setIsUploaded(false)
    await handleUpload(files)
    setIsEditing(false)
    setIsUploading(false)
    setIsUploaded(true)
    setFiles([])
    setFile(null)
  }

  const onDragEnter = (files) => {
    setFiles(files)
    setDropzoneActive(true)
  }

  const onDragLeave = (files) => {
    setFiles(files)
    setDropzoneActive(false)
  }

  const handleDownloadClick = () => window.open(fileUrl, '_blank');

  return(
    <div>
      {fileUrl && !isEditing &&
        <>
          <Button onClick={ handleDownloadClick }>
            <InsertDriveFile style={ styles.icon } />
          </Button>
          { handleDelete &&
            <Button
              color='primary'
              size="small"
              style={ styles.editButton }
              onClick={ handleDelete }>
              Remove
            </Button>
          }
        </>
      }

      { isEditing || !fileUrl &&
        <StyledDropzone
          disableClick={ false }
          onDrop={ onDrop }
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          multiple={ true }
          activeStyle={ styles.dropzoneActive}>

        {(!isUploaded && !isUploading && !dropzoneActive) &&
          <Typography variant="overline">
            Drag and drop
          </Typography>
        }

        { isUploaded &&
          <Typography variant="overline">
            Upload complete
          </Typography>
        }

        { !isUploaded && !isUploading && dropzoneActive &&
          <Typography variant="overline">
            Drop file
          </Typography>
        }

        { !isUploaded && isUploading &&
          <Typography variant="overline">
            Uploading ...
          </Typography>
        }

        { isUploading && <LinearProgress /> }
      </StyledDropzone>
      }

    </div>
  )
}

export default FileUploader
