import React from 'react'
import { Box } from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'
import { primaryDark } from 'theme'
import styled from 'styled-components'

//const start = indigo[700]
//const finish = indigo[500]

const start = indigo[700]
const finish = indigo[500]

const CSSGradient = styled(Box)`
  height: 100px;
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient( 181deg, ${start} 0%, ${finish} 100% );
  margin-bottom: 100px;
`

export default CSSGradient;
