import React from 'react'
import {
  Typography,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  List,
  Select,
  Switch,
  Checkbox
} from '@material-ui/core'

const CheckboxGroupInput = ({
    style,
    label,
    options,
    handleChange,
    ...props
  }) => (
  <FormControl style={ style }>
    <FormGroup>
      <Typography variant="overline">
        { label }
      </Typography>
      {options &&
       options.map((option, idx) => (
         <FormControlLabel
           key={ idx }
           control={
             <Checkbox
               name={ option.name }
               checked={ option.value ? true : false }
               onChange={ handleChange }
               value={ true } />
             }
           label={
             <Typography variant='body2'>{ option.label }</Typography>
           }
         />
       ))}
  </FormGroup>
</FormControl>
)

export default CheckboxGroupInput
