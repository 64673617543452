import React, { useState, useEffect } from 'react'
import {
  useAlerts,
  useCategories
} from 'hooks'
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Grid,
  Typography,
  IconButton
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import TextInput from 'components/inputs/TextInput'
import {
  ExpandMore,
  ChevronRight,
  ArrowDropDown,
  ArrowDropUp,
  KeyboardArrowDown
} from '@material-ui/icons'
import SubdirectoryArrowRight from 'resources/images/icons/subdirectory_arrow_right.svg'
import { orderBy } from 'lodash'
import styled from 'styled-components'
import {
  sortByParent,
  filterParents,
  filterChildren
} from 'helpers/categories'
import CategoryItem from 'containers/common/categories/CategoryItem'
import { syntheticEvent } from 'helpers/utils'

const useStyles = makeStyles(theme => ({
  list: {
    padding: 0
  },
  treeParent: {
    fontColor: theme.palette.text.secondary
  },
  treeChild: {
    fontColor: theme.palette.text.secondary
  }
}));

const CategoryTree = ({
    value,
    handleClick,
    ...props
  }) => {

  const classes = useStyles()
  const [parents, setParents] = useState()
  const [children, setChildren] = useState()

  const {
    categories,
    fetchCategories,
    isLoaded,
    isLoadeding,
    isEmpty,
    meta,
    page,
    perPage,
    totalCount
  } = useCategories({})

  const [open, setOpen] = useState(true)

  const handleToggleExpand = () => setOpen(!open)

  const handleInputClick = (category) => {
    handleClick(category)
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    if(categories){
      setParents(categories.filter(filterParents))
      setChildren(categories.filter(filterChildren))
    }
  }, [isLoaded, categories])

  let isFetched = parents && parents.length > 0
  return(
    <Paper elevation={1}>
      <List className={ classes.list }>
        <ListItem button onClick={ handleToggleExpand }>
          <ListItemText
            primary={
              <Typography variant='overline'>Category</Typography>
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={ handleToggleExpand }>
              { open ? <ArrowDropUp /> : <ArrowDropDown /> }
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      { isFetched && open &&
        <List className={ classes.list }>
          { parents && orderBy(parents, ['name','desc']).map((parent, i) => (
            <CategoryItem
              isParent={ true }
              key={ i }
              label={ parent.name }
              parent={ parent }
              categories={ orderBy(children.filter(c => c.parentId == parent.id), ['name','desc']) }
              handleClick={ handleInputClick }              
            />
          ))}
        </List>
    }
  </Paper>
  )
}

export default CategoryTree
