import React, { useState, createContext } from 'react'

export const AppContext = createContext()

export const AppProvider = ({ children }) => {

  const [authenticated, setAuthenticated] = useState()
  const [currentUser, setCurrentUser] = useState()
  const [token, setToken] = useState()
  const [algoliaToken, setAlgoliaToken] = useState()

  const [alert, setAlert] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const [showMenu, setShowMenu] = useState(false)
  const handleToggleMenu = () => setShowMenu(!showMenu)

  const value = {
    authenticated,
    setAuthenticated,
    currentUser,
    setCurrentUser,
    token,
    setToken,
    algoliaToken,
    setAlgoliaToken,
    alert,
    setAlert,
    isLoading,
    isLoaded,
    setIsLoaded,
    setIsLoading,
    showMenu,
    handleToggleMenu
  }

  return(
    <AppContext.Provider value={ value }>
      { children }
    </AppContext.Provider>
  )
}
