import React, { lazy } from 'react'
import { Route } from 'react-router'
import { Switch, Redirect } from 'react-router-dom'
import Loadable from 'react-loadable'
import {
  AuthRoute,
  AdminRoute,
  PublicRoute,
  PrivateRoute,
  Loading
} from 'components'
import pMinDelay from 'p-min-delay'

const DELAY = 200

//Guest
const HomeLanding = lazy(() => import("containers/guest/home/HomeLanding"));
const PricingLanding = lazy(() => pMinDelay(import('containers/guest/pricing/PricingLanding'), DELAY))
const PrivacyPolicy = lazy(() => pMinDelay(import('containers/guest/static/Privacy'), DELAY))
const TermsOfService = lazy(() => pMinDelay(import('containers/guest/static/Terms'), DELAY))
const AboutLanding = lazy(() => pMinDelay(import('containers/guest/static/About'), DELAY))
const ContactNew = lazy(() => pMinDelay(import('containers/guest/contacts/ContactNew'), DELAY))


//Auth
const Login = lazy(() => pMinDelay(import('containers/app/auth/Login'), DELAY))
const Signup = lazy(() => pMinDelay(import('containers/app/auth/Signup'), DELAY))
const ForgotPassword = lazy(() => pMinDelay(import('containers/app/auth/ForgotPassword'), DELAY))
const ResetPassword = lazy(() => pMinDelay(import('containers/app/auth/ResetPassword'), DELAY))

//App
const DashboardList = lazy(() => pMinDelay(import('containers/app/dashboards/DashboardList'), DELAY))
const DocumentShow = lazy(() => pMinDelay(import('containers/app/documents/DocumentShow'), DELAY))
const DocumentEdit = lazy(() => pMinDelay(import('containers/app/documents/DocumentEdit'), DELAY))
const DocumentSearch = lazy(() => pMinDelay(import('containers/app/documents/DocumentList'), DELAY))
const DocumentUploader = lazy(() => pMinDelay(import('containers/app/documents/DocumentUploader'), DELAY))
const Pending = lazy(() => pMinDelay(import('containers/app/dashboards/Pending'), DELAY))
const PaymentList = lazy(() => pMinDelay(import('containers/app/payments/PaymentList'), DELAY))
const PaymentEdit = lazy(() => pMinDelay(import('containers/app/payments/PaymentEdit'), DELAY))
const TemplateEdit = lazy(() => pMinDelay(import('containers/app/templates/TemplateEdit'), DELAY))
const TemplateList = lazy(() => pMinDelay(import('containers/app/templates/TemplateList'), DELAY))
const UserShow = lazy(() => pMinDelay(import('containers/app/users/UserShow'), DELAY))
const UserEdit = lazy(() => pMinDelay(import('containers/app/users/UserEdit'), DELAY))
const UserList = lazy(() => pMinDelay(import('containers/app/users/UserList'), DELAY))

//Admin
const AdminCompanyList = lazy(() => pMinDelay(import('containers/admin/companies/CompanyList'), DELAY))
const AdminCompanyEdit = lazy(() => pMinDelay(import('containers/admin/companies/CompanyEdit'), DELAY))
const AdminCompanyShow = lazy(() => pMinDelay(import('containers/admin/companies/CompanyShow'), DELAY))
const AdminUserList = lazy(() => pMinDelay(import('containers/admin/users/UserList'), DELAY))
const AdminUserEdit = lazy(() => pMinDelay(import('containers/admin/users/UserEdit'), DELAY))
const AdminUserShow = lazy(() => pMinDelay(import('containers/admin/users/UserShow'), DELAY))

//Guest
const GuestDocumentSearch = lazy(() => pMinDelay(import('containers/guest/documents/DocumentList'), DELAY))
const GuestDocumentShow = lazy(() => pMinDelay(import('containers/guest/documents/DocumentShow'), DELAY))
const GuestLinkShow = lazy(() => pMinDelay(import('containers/guest/links/LinkShow'), DELAY))

const Routes = () => (
  <Switch>
    <PublicRoute path="/" component={ HomeLanding } exact />
    <PublicRoute path="/login/:token" component={ Login } />
    <PublicRoute path="/login" component={ Login } />
    <PublicRoute path="/signup" component={ Signup } />
    <PublicRoute path="/forgot_password" component={ ForgotPassword } exact />
    <PublicRoute path="/reset_password/:token" component={ ResetPassword } exact />

    <Route path="/pricing" component={ PricingLanding } />
    <Route path="/pending" component={ Pending } exact/>
    <Route path="/contact_us" component={ ContactNew } exact/>
    <Route path="/terms" component={ TermsOfService } exact />
    <Route path="/privacy" component={ PrivacyPolicy } exact />
    <Route path="/about" component={ AboutLanding } exact />

    <Route path="/content" component={ GuestDocumentSearch } exact/>
    <Route path="/documents/:id" component={ GuestDocumentShow } exact/>
    <Route path="/shares/:id" component={ GuestLinkShow } exact/>

    <AuthRoute path="/app/documents" component={ DocumentSearch } exact/>
    <AuthRoute path="/app/documents/:id" component={ DocumentShow } exact/>
    <AuthRoute path="/app/documents/:id/edit" component={ DocumentEdit } exact/>

    <AuthRoute path="/app/upload" component={ DocumentUploader } exact/>
    <AuthRoute path="/app/dashboard" component={ DashboardList } exact/>
    <AuthRoute path="/app/payments" component={ PaymentList } exact/>
    <AuthRoute path="/app/payments/edit" component={ PaymentEdit } exact/>
    <AuthRoute path="/app/plans" component={ PaymentList } exact/>
    <AuthRoute path="/app/users" component={ UserList } exact/>
    <AuthRoute path="/app/users/:id" component={ UserShow } exact />
    <AuthRoute path="/app/users/:id/edit" component={ UserEdit } exact />

    <AdminRoute path="/admin/companies" component={ AdminCompanyList } exact/>
    <AdminRoute path="/admin/companies/new" component={ AdminCompanyEdit } exact/>
    <AdminRoute path="/admin/companies/:id" component={ AdminCompanyShow } exact/>
    <AdminRoute path="/admin/companies/:id/edit" component={ AdminCompanyEdit } exact/>
    <AdminRoute path="/admin/users" component={ AdminUserList } exact/>
    <AdminRoute path="/admin/users/new" component={ AdminUserEdit } exact/>
    <AdminRoute path="/admin/users/:id" component={ AdminUserShow } exact />
    <AdminRoute path="/admin/users/:id/edit" component={ AdminUserEdit } exact/>

    <Route component={ HomeLanding } />
  </Switch>
)

export default Routes
