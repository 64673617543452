import React, { useContext } from 'react'
import { AppContext } from 'context'
import { Route } from 'react-router'
import { Redirect } from 'react-router-dom'

const PublicRoute = ({
    component: Component,
    redirect,
    ...rest
  }) => {

  const { authenticated } = useContext(AppContext)

  return(
    <Route {...rest} render={(props) => (
      !!authenticated ? <Redirect to='/app/dashboard' /> : <Component {...props} />
    )} />
  )
}

export default PublicRoute
