import React from 'react'
import List from '@material-ui/core/List'
import {
  Typography,
  Chip,
} from '@material-ui/core'
import { Done, Clear } from '@material-ui/icons'
import { indigo } from '@material-ui/core/colors'
import styled from 'styled-components'

const StyledChip = styled(Chip)`
  margin: 0 10px 10px 0;
  background-color: ${indigo[700]};
`

const FacetList = ({ facets, handleRemoveClick }) => (
  <>
    { facets && facets.length > 0 &&
      facets.map((facet, idx) => (
      <StyledChip
        key={ idx }
        label={ facet.label }
        deleteIcon={ <Clear />}
        onDelete={() => handleRemoveClick(facet)}
        color="primary"
        />
    ))}
  </>
)

export default FacetList
