import React from 'react'
import {
  PersonOutline,
  Assignment,
  Search,
  People,
  Folder,
  CreditCard,
  Layers,
  CloudCircle,
  Home
} from '@material-ui/icons'

const styles = {
  icon: {
    color: "#333",
    fontSize: '20px'
  }
}

export const MENU_ITEMS = {
  user: [
    {value: 'dashboard', text: 'Home', icon: Home },
    {value: 'documents', text: 'Search', icon: Search },
    {value: "upload", text:'Upload', icon: CloudCircle },
  ],
  settings: [
    {value: 'users', text: 'Team'},
    {value: 'payments', text: 'Subscription'},
    {value: 'editUser', text: 'My Account'}
  ],
  public: [
    {value: 'contactUs', text: 'Contact us'},
    {value: 'content', text: 'Content'},
    {value: 'pricing', text: 'Pricing'},
    {value: 'login', text: 'Sign in'},
    {value: 'signup', text: 'Register'}
  ],
  admin: [
    {value: 'adminUsers', text: 'Users'},
    {value: 'adminCompanies', text: 'Companies'}
  ]

}
