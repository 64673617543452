import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useResource,
  usePositions,
  useNavigation,
  useLoaders,
  useAlerts
} from 'hooks'
import { validateUpdateDocument } from 'helpers/validations/document'

const useDocuments = ({ id, ...props }) => {

  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    resourceId: documentId,
    isLoading,
    isLoaded,
    isEditing,
    isValid,
    resource: document,
    resources: documents,
    fetch: fetchDocument,
    fetchAll: fetchDocuments,
    update: updateDocument,
    create: createDocument,
    save: saveDocument,
    destroy: deleteDocument,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    refresh: refreshDocument,
    refreshAll: refreshDocuments,
    showValidationErrors
  } = useResource({
    id: id,
    url: '/api/v1/app/documents',
    resourceName: 'document',
    validateFn: validateUpdateDocument
  })


  const uploadDocument = (file, document) => {
    let { version, categoryId, companyId } = document
    const formData = new FormData()
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    if(file){
      formData.append('document[attachment]', file)
      formData.append('document[version]', version)
      formData.append('document[categoryId]', categoryId)
      formData.append('document[companyId]', companyId)
    }
    return api.post('/api/v1/app/documents', formData, config)
  }


  const addTags = async (documentIds, tags) => {
    try{
      showLoading()
      await api.post(`/api/v1/app/documents/add_tags`, {
        documentIds,
        tags
      })
      showAlertSuccess('Tags added successfully')
      hideLoading()
    }catch(e){
      console.log(e)
      showAlertError('There was an error adding tags')
    }
  }

  const removeTags = async (documentIds, tags) => {
    try{
      showLoading()
      await api.post(`/api/v1/app/documents/remove_tags`, {
        documentIds,
        tags
      })
      showAlertSuccess('Tags removed successfully')
      hideLoading()
    }catch(e){
      console.log(e)
      showAlertError('There was an error adding tags')
    }
  }

  const bulkDelete = async (documentIds) => {
    try{
      showLoading()
      await api.post(`/api/v1/app/documents/bulk_delete`, {
        documentIds
      })
      showAlertSuccess('Documents deleted successfully')
      hideLoading()
    }catch(e){
      console.log(e)
      showAlertError('There was an error deleting the files')
    }
  }


  return {
    isLoading,
    isLoaded,
    isEditing,
    isValid,
    uploadFile,
    deleteFile,
    uploadDocument,
    document,
    documents,
    fetchDocument,
    fetchDocuments,
    saveDocument,
    updateDocument,
    createDocument,
    deleteDocument,
    addTags,
    removeTags,
    bulkDelete,
    handleChange,
    handleChangePage,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    refreshDocument,
    refreshDocuments,
    showValidationErrors
  }
}

export default useDocuments
