import React, { useEffect, useState } from 'react'
import { Typography, Box, IconButton, Button, Avatar } from '@material-ui/core'
import { MoreHoriz, ArrowBack, ArrowForward, DoubleArrow } from '@material-ui/icons'
import { indigo } from '@material-ui/core/colors'
import styled from 'styled-components'

const Text = styled(Typography)`
  ${props => props.highlight === 'true' && `color: #fff;`}
`

const PageButton = styled(IconButton)`
  height: 34px;
  width: 34px;
  padding: 6px;
  font-size: 14px;
  ${props => (props.highlight === 'true' && `background-color: ${indigo[700]};`)}
`


const SearchPagination = ({ currentPage, nbPages, refine }) => {
  const [rangeWithDots, setRangeWithDots] = useState([])

  const pagination = (current, last) => {
    let delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        range.push(i)
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1)
        } else if (i - l !== 1) {
          rangeWithDots.push('...')
        }
      }
      rangeWithDots.push(i)
      l = i
    }

    setRangeWithDots(rangeWithDots)
  }

  useEffect(() => {
    pagination(currentPage, nbPages)
  }, [currentPage, nbPages])

  return (
    nbPages > 1 ?
    <Box display="flex" justifyContent="align-center" width="100%">
      <div>
        <PageButton
          disabled={currentPage <= 1 ? true : false}
          size="small"
          onClick={e => refine(currentPage - 1)}>
          <ArrowBack />
        </PageButton>
      </div>
      {rangeWithDots.length > 1 &&
        rangeWithDots.map((page, i) =>
          page === '...' ? (
            <PageButton key={i}>
              <MoreHoriz />
            </PageButton>
          ) : (
            <PageButton
              key={i}
              highlight={page === currentPage ? 'true' : 'false'}
              size="small"
              onClick={e => refine(page)}
            >
              <Text
                highlight={page === currentPage ? 'true' : 'false'}
                variant='overline'>
                {page}
              </Text>
            </PageButton>
          )
        )}
      <div>
        <PageButton
          disabled={currentPage === nbPages ? true : false}
          size="small"
          onClick={e => refine(currentPage + 1)}
        >
          <ArrowForward />
        </PageButton>
      </div>
    </Box> : null
  )
}

export default SearchPagination
