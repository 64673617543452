import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useResource,
  useNavigation,
  useLoaders,
  useAlerts
} from 'hooks'
import { validateContact } from 'helpers/validations/contact'

const useGuestContacts = ({ id, ...props }) => {

  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    resourceId: contactId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: contact,
    resources: contacts,
    fetch: fetchContact,
    fetchAll: fetchContacts,
    update: updateContact,
    create: createContact,
    save: saveContact,
    destroy: deleteContact,
    handleChange,
    handleChangePage,
    refresh: refreshContact,
    refreshAll: refreshContacts,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    setResource: setContact,
    showValidationErrors
  } = useResource({
    id: id,
    url: '/api/v1/contacts',
    resourceName: 'contact',
    validateFn: validateContact
  })

  return {
    contactId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    contact,
    fetchContact,
    handleChange,
    createContact,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    refreshContact,
    setContact,
    showValidationErrors
  }
}

export default useGuestContacts
