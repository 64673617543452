import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  FormControl,
  TextField,
  Typography,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core'
import { Clear, Search } from '@material-ui/icons'
import { blue } from '@material-ui/core/colors'
import { syntheticEvent } from 'helpers/utils'
import styled from 'styled-components'

const StyledTextField = styled(TextField)`
  margin: 2px 0 0 0;
  background-color: white;
`

const StyledIconButton = styled(IconButton)`
  margin-right: -8px;
  padding: 4px;
`

const ClearIcon = styled(Clear)`
  width: 16px;
  height: 16px;
`

const SearchInput = ({
  variant="outlined",
  margin='dense',
  name,
  label,
  value,
  placeholder,
  onKeyPress,
  ...props
}) => {

  const handleClearClick = () => {
    let e = syntheticEvent('', name)
    props.handleChange(e)
    if(props.handleClearClick) props.handleClearClick();
  }

  const handleChange = ev => {
    let { name, value } = ev.target
    props.handleChange(ev)
  }

  return (
    <>
    { label &&
      <Typography variant='overline'>{ label }</Typography>
    }
    <StyledTextField
      type="text"
      fullWidth
      name={name}
      variant={ variant }
      margin={ margin }
      placeholder={placeholder}
      onKeyPress={onKeyPress}
      autoComplete="off"
      onChange={e => handleChange(e)}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment:
          value && value.length > 0 ? (
            <InputAdornment position="end">
              <StyledIconButton onClick={handleClearClick}>
                <ClearIcon />
              </StyledIconButton>
            </InputAdornment>
          ) : null,
      }}
    />
    </>
  )
}

SearchInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
}

export default SearchInput
