import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useResource,
  useNavigation,
  useLoaders,
  useAlerts
} from 'hooks'
import { validateLink } from 'helpers/validations/link'

const useGuestLinks = ({ id, ...props }) => {

  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    resourceId: linkId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: link,
    resources: links,
    fetch: fetchLink,
    fetchAll: fetchLinks,
    update: updateLink,
    create: createLink,
    save: saveLink,
    destroy: deleteLink,
    handleChange,
    handleChangePage,
    refresh: refreshLink,
    refreshAll: refreshLinks,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    setResource: setLink,
    showValidationErrors
  } = useResource({
    id: id,
    url: '/api/v1/links',
    resourceName: 'link',
    validateFn: validateLink
  })

  return {
    isLoading,
    isLoaded,
    isValid,
    link,
    fetchLink,
    refreshLink
  }
}

export default useGuestLinks
