import { Box, List, Chip  } from '@material-ui/core'
import styled from 'styled-components'

export const StyledContainer = styled(Box)`
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
`

export const StyledChip = styled(Chip)`
  background-color: #ccc;
  border-radius: 5px;
  padding: 0px;
  font-size: 11px;
`

export const StyledList = styled(List)`
  padding: 0;
`
