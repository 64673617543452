import React, { useState, useEffect, useContext } from 'react'
import {
  useAuth,
  useMenu,
  useAlerts
} from 'hooks'
import { AppContext } from 'context'
import { useHistory } from 'react-router-dom'
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Hidden
} from '@material-ui/core'
import {
  Menu,
  Close,
  Search,
  Pageview,
  CloudCircle
} from '@material-ui/icons'
import Logo from 'resources/images/logos/logo.svg'
import {
  MenuItem,
  Drawer,
  ProfileAutosuggest,
  Toolbar as SearchToolbar,
  UserAvatar,
  AccountMenu,
  AutocompleteSearch
} from 'components'
import styled from 'styled-components'
import { indigo } from '@material-ui/core/colors'
import { primaryDark } from 'theme'
import { get } from 'lodash'

const MenuBox = styled(Box)`
  margin-top: 2px;
`

const SearchBox = styled(Box)`
  margin-top: 6px;
`

const StyledMenu = styled(Menu)`
  color: white;
`
const StyledClose = styled(Close)`
  color: white;
`

const AppHeader = styled(AppBar)`
  width: 100%;
  background-color: ${primaryDark};
`

const StyledAvatarButton = styled(Button)`
  color: white;
  margin-top: 5px;
`

const UploadIcon = styled(CloudCircle)`
  margin-right: 5px;
  font-size: 32px;
`

const SearchIcon = styled(Search)`
  font-size: 32px;
`


const StyledButton = styled(Button)`
  color: white;
  margin-top: 10px;
  padding: 2px 20px;
`

const Header = ({
    ...props
  }) => {

  const {
    authenticated,
    currentUser
  } = useContext(AppContext)

  const {
    fetchMe,
    logoutUser,
    authenticateFromToken
  } = useAuth()

  const history = useHistory()

  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openAccountMenu, setOpenAccountMenu] = useState(false)

  const toggleModal = () => setOpenModal(!openModal)
  const toggleMenu = () => setOpen(!open)
  const closeMenu = () => setOpen(false)

  const {
    open: openMenu,
    anchorEl,
    handleToggleMenu,
    closeMenu: closeAccountMenu
  } = useMenu()

  const { showAlertSuccess } = useAlerts()

  const handleClick = async (action) => {
    closeMenu()
    closeAccountMenu()
    switch(action){
      case 'home':
        authenticated ? history.push('/app/dashboard') : history.push('/');
        return;
      case 'authentications':
        return history.push('/app/authentications')
      case 'content':
        return history.push('/content')
      case 'upload':
        return history.push('/app/upload')
      case 'invoices':
        return history.push('/app/invoices')
      case 'documents':
        return history.push('/app/documents')
      case 'templates':
        return history.push('/app/templates')
      case 'payments':
        return history.push('/app/payments')
      case 'users':
        return history.push(`/app/users`)
      case 'editUser':
        return history.push(`/app/users/me/edit`)
      case 'search':
        return history.push('/app/search')
      case 'contactUs':
        return history.push('/contact_us')
      case 'login':
        return history.push('/login')
      case 'logout':
        return handleLogoutClick();
      case 'signup':
        return history.push('/signup')
      case 'pricing':
        return history.push('/pricing')
      case 'adminDocuments':
        return history.push('/admin/documents')
      case 'adminTemplates':
        return history.push('/admin/templates')
      case 'adminCompanies':
        return history.push('/admin/companies')
      case 'adminUsers':
        return history.push('/admin/users')
      default:
        return history.push('/')
    }
  }

  const handleLogoutClick = async () => {
    await logoutUser()
    setOpen(false)
  }

  useEffect(() => {
    if(authenticated && !currentUser) fetchMe();
  }, [authenticated])

  return(
      <>
        <AppHeader position="fixed" elevation={0}>
          <Toolbar variant="dense">
            <Box display='flex' justifyContent='space-between' flexDirection='row' width='100%'>
              <Box display='flex' flexDirection='row' justifyContent='flex-start'>
                <MenuBox>
                  <IconButton
                    onClick={ toggleMenu }
                    color="inherit">
                    { open ? <StyledClose /> : <StyledMenu /> }
                  </IconButton>
                </MenuBox>
                <Button onClick={ (e) => handleClick("home")}>
                  <img src={ Logo } width={ 140 } />
                </Button>
              </Box>
              <Box>
                {currentUser ?
                  <>
                  <StyledAvatarButton
                    onClick={() => handleClick('documents')}>
                    <SearchIcon />
                    <Hidden only={['xs']}>Search</Hidden>
                  </StyledAvatarButton>
                  <Hidden only={['xs']}>
                    <StyledAvatarButton
                      onClick={() => handleClick('upload')}>
                      <UploadIcon />
                        Upload
                    </StyledAvatarButton>
                  </Hidden>
                  <StyledAvatarButton onClick={ handleToggleMenu }>
                    <UserAvatar
                      online={ true }
                      size={30}
                      imageUrl={ currentUser.imageUrl }
                    />
                    <Hidden only={['xs']}>
                      <Box mx={1}>{ currentUser.fullName }</Box>
                    </Hidden>
                  </StyledAvatarButton>
                  <AccountMenu
                    open={ openMenu }
                    anchorEl={ anchorEl }
                    handleToggleMenu={ handleToggleMenu }
                    handleClick={ handleClick }
                  />
                  </>
                  :
                  <>
                    <Hidden only={['sm', 'xs']}>
                      <StyledButton
                        onClick={(e) => handleClick('contactUs')}>
                        Contact us
                      </StyledButton>
                      <StyledButton
                        onClick={(e) => handleClick('content')}>
                        Content
                      </StyledButton>
                      <StyledButton
                        onClick={(e) => handleClick('pricing')}>
                        Pricing
                      </StyledButton>
                      <StyledButton
                        onClick={(e) => handleClick('login')}>
                        Sign In
                      </StyledButton>
                    </Hidden>
                    <StyledButton
                      color='secondary'
                      variant='contained'
                      onClick={(e) => handleClick('signup')}>
                      Register
                    </StyledButton>
                  </>
                }
              </Box>
            </Box>
          </Toolbar>
        </AppHeader>
        <Drawer
          open={ open }
          toggleMenu={ toggleMenu }
          authenticated={ authenticated }
          currentUser={ currentUser }
          handleClick={ handleClick }
        />
      </>
  )
}

export default Header
