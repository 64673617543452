import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import {
  Box,
  Typography,
  FormControl,
  Grid,
  Chip,
  makeStyles
} from '@material-ui/core'
import TextInput from 'components/inputs/TextInput'
import {
  Clear
} from '@material-ui/icons'
import _ from 'lodash'
import styled from 'styled-components'
import { syntheticEvent } from 'helpers/utils'
import { get } from 'lodash'
import { indigo } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: 'white'
  }
}))


const StyledTypography = styled(Typography)`
  padding-top: 4px;
  padding-bottom: 4px;
`
const StyledClear = styled(Clear)`
  height: 16px;
  width: 16px;
`

const filterOptions = createFilterOptions(config => ({
  trim: true,
  ignoreAccents: true,
  ignoreCase: true,
}))

const MultipleAutosuggest = ({
    value,
    label,
    name,
    handleChange,
    placeholder,
    ...props
  }) => {

  const classes = useStyles()
  const [option, setOption] = useState()

  const handleInputChange = (event, inputValues) => {
    let ev = {}
    if(inputValues){
      let ev = syntheticEvent(inputValues, name)
      handleChange(ev)
    }
  }

  return(
    <FormControl fullWidth>
      <StyledTypography variant="overline">
        { label }
      </StyledTypography>
      <Autocomplete
        id='autosuggest'
        multiple
        freeSolo
        filterOptions={ filterOptions }
        value={ value }
        options={[]}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              className={ classes.chip }
              color='primary'
              variant="outlined"
              label={option}
              deleteIcon={<StyledClear />}
              {...getTagProps({ index })}
            />
          ))
        }
        onChange={ handleInputChange }
        size='small'
        renderInput={params =>
          <TextField {...params}
            className={ classes.textField }
            placeholder={ placeholder }
            variant="outlined"
          />
        }
      />
    </FormControl>
  )
}

export default MultipleAutosuggest
