import React from 'react'
import { Button } from '@material-ui/core'
import mobile from 'is-mobile'

const MobileButton = ({ children, ...props }) => {
  const isMobile = mobile()
  return(
    <Button
      { ...props }
      fullWidth={ isMobile ? true : false}
    >
     { children }
    </Button>
  )
}

export default MobileButton
