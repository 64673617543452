import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  useLoaders,
  useAlerts
} from 'hooks'
import api from 'api'
import { AppContext } from 'context'
import { get } from 'lodash'

const useAuth = ({ ...props }) => {

  const history = useHistory()

  const {
    showLoading,
    hideLoading
  } = useLoaders()

  const {
    showAlertError,
    showAlertSuccess
  } = useAlerts()

  const {
    authenticated,
    setAuthenticated,
    currentUser,
    setCurrentUser,
    setToken,
    setAlgoliaToken
  } = useContext(AppContext)

  const [user, setUser] = useState({})

  const handleChange = (ev) => {
    const { name } = ev.target
    let value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value
    setUser({
      ...user,
      [name]: value
    })
  }

  const fetchMe = async () => {
    try{
      showLoading()
      let resp = await api.get('/api/v1/app/users/me')
      if(resp && resp.data){
        setCurrentUser(resp.data)
      }
      hideLoading()
      return resp.data
    }catch(e){
      hideLoading()
      console.log(e)
    }
  }

  const updateMe = async (user) => {
    try{
      showLoading()
      let resp = await api.put(`/api/v1/app/users/${user.id}`, {
        user
      })
      setCurrentUser(resp.data)
      hideLoading()
      return resp.data
    }catch(e){
      hideLoading()
      console.log(e)
    }
  }

  const loginUser = async (user) => {
    try{
      showLoading()
      let resp = await api.post('/api/v1/login', {
        user
      })
      if(resp.data){
        setCurrentUser(resp.data)
        setAuthenticated(true)
        await setTokens(resp.data)
        window.location.href = '/app/dashboard'
        return resp.data
      }else{
        return false
      }
    }catch(e){
      hideLoading()
      showAlertError('Email or password is incorrect')
      console.log(e)
    }
  }

  const signupUser = async (user) => {
    try{
      showLoading()
      let resp = await api.post('/api/v1/signup', {
        user
      })
      hideLoading()
      if(resp.data){
        setCurrentUser(resp.data)
        setAuthenticated(true)
        setTokens(resp.data)
        return resp.data
      }else{
        return false
      }
    }catch(e){
      hideLoading()
      console.log(e)
      showAlertError('Unable to create the account. Email may already exist.')
    }
  }

  const resetPassword = async (password, token) => {
    try{
      showLoading()
      let resp = await api.post('/api/v1/reset_password', {
          password: password,
          change_password_token: token
      })
      hideLoading()
      if(resp.data){
        setCurrentUser(resp.data)
        setAuthenticated(true)
        window.location.href = '/app/dashboard'
        return resp.data
      }else{
        return false
      }
    }catch(e){
      hideLoading()
      console.log(e)
    }
  }

  const forgotPassword = async (email) => {
    try{
      showLoading()
      let resp = await api.post('/api/v1/forgot_password', {
        email
      })
      setCurrentUser(resp.data)
      hideLoading()
      return resp.data
    }catch(e){
      hideLoading()
      console.log(e)
    }
  }

  const logoutUser = () => {
    setCurrentUser(null)
    setAuthenticated(null)
    localStorage.removeItem('authToken')
    localStorage.removeItem('algoliaToken')
    window.location.href = '/'
  }

  const authenticateFromToken = (token) => {
    if(token){
      setAuthenticated(true)
      setToken(token)
    }else{
      return false
    }
  }

  const fetchAlgoliaToken = async () => {
    let algoliaToken
    let resp = await api.get('/api/v1/app/users/me')
    let user = resp.data
    if(user && user.company){
      algoliaToken = user.company.algoliaToken
      setAlgoliaToken(algoliaToken)
      localStorage.setItem('algoliaToken',  algoliaToken)
    }
    return algoliaToken
  }

  const setTokens = (user) => {
    setToken(user.token)
    localStorage.setItem('authToken', user.token)
    if(user.company){
      const { algoliaToken } = user.company
      if(algoliaToken){
        setAlgoliaToken(algoliaToken)
        localStorage.setItem('algoliaToken',  algoliaToken)
      }
    }
  }

  useEffect(() => {
    if(currentUser && !authenticated){
      setTokens(currentUser)
      setAuthenticated(true)
    }
  }, [currentUser])

  return{
    user,
    handleChange,
    fetchMe,
    updateMe,
    loginUser,
    signupUser,
    logoutUser,
    resetPassword,
    forgotPassword,
    authenticateFromToken,
    fetchAlgoliaToken
  }
}

export default useAuth
