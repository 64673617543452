//Common
export { default as useAlerts } from 'hooks/common/useAlerts'
export { default as useLoaders } from 'hooks/common/useLoaders'
export { default as useResource } from 'hooks/common/useResource'
export { default as useSelected } from 'hooks/common/useSelected'
export { default as useMenu } from 'hooks/common/useMenu'
export { default as useNavigation } from 'hooks/common/useNavigation'
export { default as useQuery } from 'hooks/common/useQuery'
export { default as useSearch } from 'hooks/common/useSearch'
export { default as usePagination } from 'hooks/common/usePagination'

//App
export { default as useAuth } from 'hooks/app/useAuth'
export { default as useCategories } from 'hooks/app/useCategories'
export { default as useLinks } from 'hooks/app/useLinks'
export { default as usePayments } from 'hooks/app/usePayments'
export { default as useUsers } from 'hooks/app/useUsers'
export { default as useTemplates } from 'hooks/app/useTemplates'
export { default as useDocuments } from 'hooks/app/useDocuments'
export { default as useDashboard } from 'hooks/app/useDashboard'

//Admin
export { default as useAdminUsers } from 'hooks/admin/useAdminUsers'
export { default as useAdminCompanies } from 'hooks/admin/useAdminCompanies'

//Guest
export { default as useGuestContacts } from 'hooks/guest/useGuestContacts'
export { default as useGuestDocuments } from 'hooks/guest/useGuestDocuments'
export { default as useGuestLinks } from 'hooks/guest/useGuestLinks'
