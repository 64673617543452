import { isEmpty } from './validate'

export const validateCategory = (category) => {
  let isValid = true
  let messages = []
  let warnings = []

  if(isEmpty(category.name)){
    isValid = false
    messages.push('Category name is required')
  }

  let validation = {
    isValid,
    messages,
    warnings
  }

  return  validation
}
