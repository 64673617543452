import React from 'react'
import {
  Box,
  Button,
  Typography,
  Grid
} from '@material-ui/core'
import {
  Send,
  ArrowForward,
  PersonOutline
} from '@material-ui/icons'
import {
  BlueBox,
  CheckboxGroupInput,
  SelectInput,
  Autosuggest,
  TextInput
} from 'components'
import styled from 'styled-components'
import { blue, indigo, purple, green } from '@material-ui/core/colors'

const Text = styled(Typography)`
  line-height: 1.0em;
`

const StyledBox = styled(Box)`
  border-radius: 5px;
  margin-top: 5px;
  ${(({active}) => active ? `background-color: #E9EBF7;` : '')};
  &:hover {
    background-color: #E9EBF7;
  }
`

const ChatForm = ({
  chat,
  hireTalent,
  handleChange,
  handleSubmit,
  ...props
  }) => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <TextInput
          label='Name'
          name='name'
          placeholder="Name"
          value={ chat.name }
          handleChange={ handleChange }
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          label='Email'
          name='email'
          placeholder="Email"
          value={ chat.email }
          handleChange={ handleChange }
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          label='Company'
          name='company'
          placeholder="Company"
          value={ chat.company }
          handleChange={ handleChange }
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label='Leave a message'
          multiline
          rows={ 4 }
          name='message'
          placeholder='Leave us a message'
          value={ chat.message }
          handleChange={ handleChange }
        />
      </Grid>
    </Grid>
)

export default ChatForm
