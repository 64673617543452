import React, { useState, useEffect } from 'react'
import {
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon
} from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'
import styled from 'styled-components'


const StyledAvatar = styled(Avatar)`
  margin-right: 20px;
  ${props => props.active == 'true' && `background: ${indigo[700]};`}
  height: 50px;
  width: 50px;
`

const Number = styled(Typography)`
  color: white;
  font-weight: 500;
`

const StyledText = styled(Typography)`
  && {
    ${props => props.active == 'true' &&
      `color: ${indigo[900]}; font-weight: 500; `
    }
  }
`

const ListTabItem = ({
    icon,
    step,
    value,
    primary,
    secondary,
    handleClick,
    dense=false,
    ...props
  }) => {

  return(
      <ListItem disableGutters button onClick={() => handleClick(value)}>
        <ListItemIcon>
          <StyledAvatar active={ step === value && 'true'}>
            { icon ? icon : value }
          </StyledAvatar>
        </ListItemIcon>
        <ListItemText
          primary={
            <StyledText
              variant='caption'
              active={ step === value && 'true'}>
              { primary }
            </StyledText>
          }
          secondary={
            <Typography
              color='textSecondary'
              variant='body2'>
              { secondary }
            </Typography>
          }
        />
      </ListItem>
  )
}

export default ListTabItem
