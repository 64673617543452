import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useResource,
  useNavigation
} from 'hooks'
import { validateTemplate } from 'helpers/validations/template'


const useTemplates = ({ id, ...props }) => {

  const {
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: template,
    resources: templates,
    fetch: fetchTemplate,
    fetchAll: fetchTemplates,
    update: updateTemplate,
    create: createTemplate,
    save: saveTemplate,
    destroy: deleteTemplate,
    uploadFile,
    deleteFile,
    handleChange,
    handleChangePage,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    refresh: refreshTemplate,
    refreshAll: refreshTemplates,
    showValidationErrors
  } = useResource({
    id: id,
    url: '/api/v1/app/templates',
    resourceName: 'template',
    validateFn: validateTemplate
  })

  const uploadTemplate = (file) => {
    let { version, categoryId, companyId } = document
    const formData = new FormData()
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    if(file){
      formData.append('template[attachment]', file)
    }
    return api.post('/api/v1/app/templates', formData, config)
  }

  return {
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    template,
    templates,
    fetchTemplate,
    fetchTemplates,
    saveTemplate,
    updateTemplate,
    createTemplate,
    deleteTemplate,
    uploadFile,
    deleteFile,
    uploadTemplate,
    handleChange,
    handleChangePage,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    refreshTemplate,
    refreshTemplates,
    showValidationErrors
  }
}

export default useTemplates
