import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'


const BackButton = ({ path, label, color='primary'}) =>  {

  const history = useHistory()
  const handleClick = () =>  history.push(path)

  return(
      <Button
        onClick={ handleClick }
        size="small"
        color={ color }>
        <ArrowBack />
        Go Back
      </Button>
  )
}

export default BackButton
