import React from 'react'
import {
  Container,
  Box
} from '@material-ui/core'
import styled from 'styled-components'

const OuterContainer = styled('div')`
  width: 100% ;
  ${props => `background-color: ${props.color} ;`}
  ${props => `padding: ${props.padding}px 0;`}
`

const InnerContainer = styled('div')`
  margin: 0 auto ;
  ${props => `max-width: ${props.maxWidth}px;`}
`

const Section = ({
    color='#fff',
    padding=20,
    maxWidth=950,
    children,
    ...props
  }) => (
    <OuterContainer color={ color } padding={ padding }>
      <InnerContainer maxWidth={ maxWidth }>
        { children }
      </InnerContainer>
    </OuterContainer>
)

export default Section
