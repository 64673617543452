import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Typography } from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'
import { syntheticEvent } from 'helpers/utils'
import { selectStyles } from 'components/autosuggest/styles'
import styled from 'styled-components'

const Caption = styled(Typography)`
  margin: 0 0 10px 0;
`

const StyledContainer = styled('div')`
  ${props => props.height && `height: ${props.height}px;`}
`

const Autosuggest = ({
    value,
    options,
    label,
    name,
    placeholder,
    multiselect,
    position,
    handleChange,
    isSearchable=true,
    ...props
  }) => {

  const inputProps = {
    autoComplete: 'none',
    autoCorrect: 'off',
    spellCheck: 'off'
  }

  const [option, setOption] = useState()
  const [height, setHeight] = useState()

  useEffect(() => {
    if(value) setOptionFromValue(value)
  }, [value])

  const setOptionFromValue = (value) => {
    let opt = options.find(o => o.value == value)
    setOption(opt)
  }

  const handleExpand = () => position == 'static' && setHeight(340);
  const handleShrink = () => setHeight()

  const handleInputChange = (selectedOption) => {
    setOption(selectedOption)
    let _value
    if(multiselect){
      _value = selectedOption.map(o => o && o.value)
    }else{
      _value = selectedOption && selectedOption.value
    }
    let ev = syntheticEvent(_value, name)
    handleChange(ev)
  }

    return (
      <StyledContainer height={ height }>
        <Caption variant='overline'>
          { label }
        </Caption>
        <Select
          isClearable
          autocomplete="none"
          isSearchable={ isSearchable }
          inputProps={ {autoComplete: 'off' }}
          placeholder={ placeholder }
          isMulti={ multiselect ? true : false }
          value={ option }
          styles={ selectStyles }
          onChange={ handleInputChange }
          onMenuClose={ handleShrink }
          onMenuOpen={ handleExpand }
          options={ options }
        />
    </StyledContainer>
  )
}

export default Autosuggest
