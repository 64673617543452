import React from 'react'
import {
  Box,
  Hidden,
  Grid,
  Typography
} from '@material-ui/core'
import AecomLogo from 'resources/images/logos/AECOM.svg'
import IGLogo from 'resources/images/logos/IG.svg'
import ISLogo from 'resources/images/logos/IS.svg'
import RPLogo from 'resources/images/logos/RP.svg'
import SMHLogo from 'resources/images/logos/SMH.svg'
import VCALogo from 'resources/images/logos/VCA.svg'
import WMALogo from 'resources/images/logos/WMA.svg'
import DJALogo from 'resources/images/logos/DJA.svg'
import S3877Logo from 'resources/images/logos/S3877.svg'
import styled from 'styled-components'

const Logo = styled('img')`
  margin: 0;
  height: 90px;
  max-width: 170px;
  ${props => props.logo ==='vca' && `height: 40px;`}
  ${props => props.logo ==='S3877' && `height: 20px;`}
`
const styles = {
}

const ClientLogos = () => (
  <Box
    mt={6}
    mx={2}
    display='flex'
    justifyContent='space-between'
    flexWrap='wrap'
    alignItems='center'>
    <Logo src={ AecomLogo } />
    <Logo src={ WMALogo } />
    <Logo src={ VCALogo } logo='vca' />
    <Logo src={ SMHLogo }  />
    <Logo src={ ISLogo } />
    <Logo src={ S3877Logo }  logo='S3877' />
  </Box>
)

export default ClientLogos
