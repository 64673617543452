import React from 'react'
import {
  Box,
  Paper,
  Grid,
  Button,
  IconButton,
  Menu,
  MenuItem,
  CardActionArea,
  Grow,
  Divider,
  Typography
} from '@material-ui/core'
import {
  MoreVert,
  Star,
  Check
} from '@material-ui/icons'
import {
  UserAvatar,
  DocumentSnippet
} from 'components'
import { formatCurrency } from 'helpers/utils'
import { blue } from '@material-ui/core/colors'
import styled from 'styled-components'

const StyledPaper = styled(Paper)`
  border: 2px solid white;
  ${(({selected}) => selected === "true" && `border: 2px solid ${blue[400]}`)}
`
const StyledButton = styled(Button)`
  padding: 5px;
`

const styles = {
  checkbox: {
    marginTop: '30px'
  },
  actionArea: {
    width:"100%",
    margin: '0',
    padding: '10px 0'
  },
  iconButton: {
    padding: '3px'
  },
}

const DocumentCard = ({
  menu,
  actions,
  document,
  selectable,
  selected,
  handleClick,
  handleToggleSelect
}) => (
  <Box my={1}>
    <StyledPaper selected={ selected ? "true" : "false"}>
      <Box p={1}>

      <Grid container spacing={1}>
        <Grid item xs={10} sm={11} >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' height='100%'>
                  <StyledButton onClick={(e) => handleToggleSelect(document)}>
                    <UserAvatar
                      size={ 100 }
                      imageUrl={ document.imageUrl }
                    />
                  </StyledButton>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <CardActionArea onClick={ handleClick } style={ styles.actionArea }>
                  <DocumentSnippet
                    document={ document }
                  />
                </CardActionArea>
              </Grid>
            </Grid>

        </Grid>
        <Grid item xs={2} sm={1}>
          <Box my={1}>
            { menu && menu }
          </Box>
        </Grid>
      </Grid>
    </Box>
  </StyledPaper>
  </Box>
)

export default DocumentCard
