import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  IconButton
} from '@material-ui/core'
import styled from 'styled-components'

const IMAGE_WIDTH = 140

const StyledImage = styled('img')`
  padding: 2px;
  background-color: #fff;
  margin: 0 0 10px 0;
  ${props => props.circle === "true" && `border-radius: 100px;`}
  ${props => `width: ${props.size}px; height: ${props.size}px;`}
`

const StyledDropzone = styled(Dropzone)`
  margin: 0;
  padding: 58px 20px;
  border: 1px dotted #333;
  color: #555;
  height: 140px;
  border-radius: 140px;
  width: 140px;
  background-color: #fff;
  text-align: center;
  margin: 0 0 10px 0;
`

const styles = {
  defaultImage: {
    padding: '3px',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    width: '160px',
    margin: '0 0 10px 0'
  },
  editButton: {
    margin: '0 0 0 0'
  }
}

const AvatarUploader = ({
    circle=true,
    handleUpload,
    size=IMAGE_WIDTH,
    imageUrl,
    handleDelete,
    ...props
  }) => {

  const [isEditing, setIsEditing] = useState(false)
  const [file, setFile] = useState()
  const [files, setFiles] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const [dropzoneActive, setDropzoneActive] = useState(false)


  const handleEditClick = () => setIsEditing(!isEditing)

  const onDrop = async (files) => {
    setIsUploading(true)
    setIsUploaded(false)
    await handleUpload(files[0])
    setIsEditing(false)
    setIsUploading(false)
    setIsUploaded(true)
    setFiles([])
    setFile(null)
  }

  const onDragEnter = (files) => {
    setFiles(files)
    setDropzoneActive(true)
  }

  const onDragLeave = (files) => {
    setFiles(files)
    setDropzoneActive(false)
  }

  return(
    <div>
      {imageUrl && !isEditing &&
        <StyledImage
          circle={ circle ? "true" : "false" }
          size={ size }
          src={ imageUrl }
        />
      }

      {(isEditing || !imageUrl) &&
        <StyledDropzone
          disableClick={ false }
          accept="image/*"
          onDrop={ onDrop }
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          multiple={ false }
          activeStyle={ styles.dropzoneActive}>

        {(!isUploaded && !isUploading && !dropzoneActive) &&
          <Typography variant="overline">
            Upload image
          </Typography>
        }

        { isUploaded &&
          <Typography variant="overline">
            Uploaded
          </Typography>
        }

        { !isUploaded && !isUploading && dropzoneActive &&
          <Typography variant="overline">
            Drop image
          </Typography>
        }

        { !isUploaded && isUploading &&
          <Typography variant="overline">
            Uploading ...
          </Typography>
        }

        { isUploading && <LinearProgress /> }
      </StyledDropzone>
      }

      { imageUrl && handleDelete &&
        <Box>
          <Button
            color='secondary'
            size="small"
            style={ styles.editButton }
            onClick={ handleDelete }>
            Remove
          </Button>
        </Box>
      }
    </div>
  )
}

export default AvatarUploader
