import React from 'react'
import {
  Typography,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Box,
  Hidden
} from '@material-ui/core'
import {
  LocationOn,
  Bookmark,
  Cancel,
  AccountBalance,
  Stars,
  Work,
  AccessTime,
  CheckCircle,
  VerifiedUser,
  Person,
  PersonPin,
  School,
  Email,
  Add
} from '@material-ui/icons'
import { Skill, StatusTag } from 'components'
import { formatCurrency } from 'helpers/utils'
import moment from 'moment'

const styles = {
  icon: {
    fontSize: '16px',
    marginRight: '5px',
    color: '#aaa'
  },
  rate: {
    marginTop: '10px'
  }
}

const DocumentSnippet = ({ document, isAdmin, rate, ...props }) => (
  <Box display='flex' flexDirection='row' justifyContent='space-between'>
    <Box>
      <Typography variant="h4">
        { isAdmin ? document.fullName : document.displayCategory }
      </Typography>

      {isAdmin && document.email &&
        <Typography variant="body2" color="textSecondary">
          <Email style={ styles.icon } />
          { document.email }
        </Typography>
      }


      {document.displayCategory &&
        <Typography variant="body2" color="textSecondary">
          <Bookmark style={ styles.icon } />
          { document.displayCategory }
        </Typography>
      }

      {document.contract && rate &&
        <Typography variant="body2" color="textSecondary">
          <AccountBalance style={ styles.icon } />
          { formatCurrency(rate) }/hour
        </Typography>
      }

      {document.perm && document.salary &&
        <Typography variant="body2" color="textSecondary">
          <AccountBalance style={ styles.icon } />
          { formatCurrency(document.salary) }+
        </Typography>
      }


      {document.location &&
        <Typography variant="body2" color="textSecondary">
          <LocationOn style={ styles.icon } />
          { document.location }
        </Typography>
      }

      {document.yearsOfExperience &&
        <Typography variant="body2" color="textSecondary">
          <Stars style={ styles.icon } />
           <span>{ document.yearsOfExperience }+ years of experience</span>
        </Typography>
      }

      { isAdmin && document.lastMessagedAt &&
        <Typography variant="body2" color="textSecondary">
          <Email style={ styles.icon } />
          Last messaged { document.lastMessagedAt }
        </Typography>
      }

      <Box display='flex' flexDirection='row' flexWrap='wrap'>
        {document.skills &&
          document.skills.map((skill, idx) => (
          <Skill
            key={ skill.id }
            skill={ skill }
          />
        ))}
      </Box>
    </Box>
    { isAdmin &&
      <Box>
        <StatusTag
          status={ document.status }
        />
      </Box>
    }
  </Box>
)

export default DocumentSnippet
