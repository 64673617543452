import React from 'react'
import { Container } from '@material-ui/core'
import styled from 'styled-components'

const StyledContainer = styled(Container)`
  margin: 0 auto;
  height: 100%;
  padding: 70px 10px;
  max-width: ${props => props.maxWidth == 'md' ? '800px' : '1200px'};
`

const Page = ({ maxWidth='md', children, ...props }) => (
  <StyledContainer maxWidth={ maxWidth } { ...props }>
    { children }
  </StyledContainer>
)

export default Page
