import React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { yellow } from '@material-ui/core/colors'
import styles from './styles.module.css'
import { get } from 'lodash'

const Highlight = props => {
  const { hit, attribute, variant } = props
  const renderHTML = () => {
    let html = get(hit.highlightResult[attribute], 'value')
    return { __html: html ? html : '' }
  }

  return (
    <Typography variant={variant} {...props}>
      <span dangerouslySetInnerHTML={ renderHTML() } />
    </Typography>
  )
}

export default Highlight
