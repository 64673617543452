import React, { cloneElement } from 'react'
import { Box, Typography, Avatar } from '@material-ui/core'
import styled from 'styled-components'
import { colors } from '@material-ui/core'

const StyledAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
  margin: 10px;
  background-color: ${props => colors[props.color || 'indigo'][500]};
`

const Placeholder = ({
    icon,
    color,
    title,
    button,
    description
  }) => {

  let newIcon
  if(icon){
    newIcon = cloneElement(icon, {style: { fontSize: '26px' }})
  }

  return (
      <Box  m={1} p={2}>
        <center>
          {newIcon &&
            <StyledAvatar
              color={ color }>
              { newIcon }
            </StyledAvatar>
          }

          { title &&
            <Typography variant="h5">
              { title }
            </Typography>
          }

          { description &&
            <Typography variant="body2" color='textSecondary'>
              { description }
            </Typography>
          }

          { button &&
            <Box m={3}>
              { button }
            </Box>
          }
        </center>
      </Box>
    )
}

export default Placeholder
