import { orderBy } from 'lodash'

export const isChild = (category) => (category.parentId ? true : false )
export const isParent = (category) => (category.parentId ? false : true )

export const filterParents = (category) => (isParent(category))
export const filterChildren = (category) => (isChild(category))


export const sortByParent = (categories) => {

  let sorted = []
  let parents = categories.filter(filterParents)
  let children = categories.filter(filterChildren)
  parents = orderBy(parents, ['name'], ['asc'])
  children = orderBy(children, ['name'], ['asc'])
  parents.forEach(parent => {
    sorted.push(parent)
    let _children = children.filter(c => c.parentId === parent.id)
    _children.forEach(c => sorted.push(c))
  })
  return sorted
}


export const groupByParent = (categories) => {
  const sortedCategories = {}
  categories.map(c => {
    if(!c.parentId && !sortedCategories[c.id]){
      sortedCategories[c.id] = []
    }
  })

  categories.map(c => {
    if(c.parentId){
      sortedCategories[c.parentId].push(c)
    }
  })

  return sortedCategories
}
