import React from 'react'
import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core'
import { indigo, blue } from '@material-ui/core/colors'

// Inspired by the Facebook spinners.
const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  backdrop: {
    backgroundColor: 'rgb(255, 255, 255, 0.5)',
    zIndex: 9999
  },
  top: {
    color: indigo[100],
  },
  bottom: {
    color: indigo[500],
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
}));

const Spinner = ({ isLoading, ...props }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={ isLoading }>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.top}
          size={80}
          thickness={5}
          {...props}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.bottom}
          size={80}
          thickness={5}
          {...props}
        />
      </div>
    </Backdrop>
  );
}
export default Spinner
