import React from 'react'
import { Chip } from '@material-ui/core'
import {
  indigo,
  blueGrey
} from '@material-ui/core/colors'
import styled from 'styled-components'

const StyledTag = styled(Chip)`
  margin: 5px 10px 5px 0px;
  font-size: 11px;
  padding: 0;
`

const Tag = ({label, size, color='primary'}) => (
  <StyledTag
    clickable
    variant='outlined'
    color={ color }
    label={ label }
  />
)

export default Tag
