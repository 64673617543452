import React from 'react'
import { AsyncAutosuggest } from 'components'

const CompanyAutosuggest = ({
  value,
  name='companyId',
  label,
  position,
  handleChange,
  ...props
  }) => (
  <AsyncAutosuggest
    name={name}
    label={ label }
    value={ value }
    position={ position }
    displayField='name'
    remoteUrl='/api/v1/admin/companies'
    placeholder="Select company"
    handleChange={ handleChange }
  />
)

export default CompanyAutosuggest
