import { isEmpty, isEmail } from './validate'

export const validateLink = (link) => {
  let isValid = true
  let messages = []
  let warnings = []

  /*
  if(isEmpty(link.emails)){
    isValid = false
    messages.push('At least one email is required')
  }

  if(!isEmpty(link.emails)){
    link.emails.forEach(email => {
      if(!isEmail(email)){
        isValid = false
        messages.push('Must be an email to share files')
      }
    })
  }*/


  let validation = {
    isValid,
    messages,
    warnings
  }

  return  validation
}
