import React, { useContext, useState, useEffect } from 'react'
import { useAuth } from 'hooks'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { BrowserSupport, Header, Alert, ContextSpinner } from 'components'
import { Box, CssBaseline } from '@material-ui/core'
import { muiTheme } from 'theme'
import Routes from 'routes'
import { AppContext } from 'context'
import ChatContainer from 'containers/common/chat/ChatContainer'

const theme = createMuiTheme(muiTheme)

const App = ({ ...props }) => {

  const { authenticateFromToken } = useAuth()

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) authenticateFromToken(authToken);
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
      <BrowserSupport />
      <CssBaseline />
      <Alert />
      <Header />
      <Routes {...props} />
      <ChatContainer />
      <ContextSpinner />
    </MuiThemeProvider>
  )
}


export default App
