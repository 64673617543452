import React from 'react'
import {
  OnlineStatusBadge,
  UserAvatar
} from 'components'
import {
  Badge
} from '@material-ui/core'
import { colors } from '@material-ui/core'

const OnlineStatusAvatar = ({
    user,
    size,
    online="true"
  }) => (
  <OnlineStatusBadge online={ online }>
    <UserAvatar
      imageUrl={ user.imageUrl }
      size={ size }
    />
  </OnlineStatusBadge>
)


export default OnlineStatusAvatar
