import React from 'react'
import { Box, Paper, Typography } from '@material-ui/core';
import styled from 'styled-components'

const StyledPaper = styled(Paper)`
  margin: 0 auto;
  zIndex: 999;
`
const Column  = ({ paper, children }) => {

  const renderChildren = () => (
    <Box p={2}>
      { children && children }
    </Box>
  )

  return(
  paper === true ?
    <StyledPaper elevation={1}>
      { renderChildren() }
    </StyledPaper>
    :
    renderChildren()
  )
}

export default Column
