import React, { Fragment } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'
import { primaryDark } from 'theme'
import styled from 'styled-components'


const Text = styled(Typography)`
  ${({ included}) => included === true ? '' :
    'text-decoration: line-through; color: #666; '
  };
`

const StyledHeader = styled(Box)`
  background-color: ${primaryDark};
  color: white;
  padding: 20px;
`

const StyledSubheader = styled(Box)`
  background-color: ${indigo[700]};
  color: white;
  padding: 20px;
`

const WhiteText = styled(Typography)`
  color: white;
`


const Price = ({ price, monthly, handleClick }) => (
  <Card>
    <StyledHeader>
      <WhiteText variant="h4">
        { price.title }
      </WhiteText>
    </StyledHeader>
    <StyledSubheader>
      <WhiteText variant='body1'>
        { price.price }
      </WhiteText>
    </StyledSubheader>
    <CardContent>

      <Typography variant='overline' color="textSecondary">
        FEATURES
      </Typography>
      <Typography variant="body2">
        { price.features.map((f, i) => (
          <ListItem dense key={i} button>
            <ListItemText
              primary={
                <Text included={ f.included }>
                  { f.text }
                </Text>
              }
            />
          </ListItem>
        ))}
      </Typography>
    </CardContent>
    { handleClick &&
    <CardActions>
      <Button
        onClick={(e) => handleClick(price) }
        fullWidth
        size='large'
        variant='contained'
        color='secondary'>
        Learn More
      </Button>
    </CardActions>
    }
  </Card>
)

export default Price
