import React, { useState, useEffect, useContext } from 'react'
import classNames from 'classnames'
import {
  Button,
  Snackbar,
  SnackbarContent,
  IconButton
} from '@material-ui/core'
import { AppContext } from 'context'
import {
  Close,
  Check,
  Error as ErrorIcon,
  Info,
  Warning
} from '@material-ui/icons'
import { colors } from '@material-ui/core'
import { get } from 'lodash'

const variantIcon = {
  success: Check,
  warning: Warning,
  error: ErrorIcon,
  info: Info,
}

const styles = {
  success: {
    backgroundColor: colors.green[500],
  },
  error: {
    backgroundColor: colors.purple[500],
  },
  info: {
    backgroundColor: colors.amber[700],
  },
  warning: {
    backgroundColor: colors.amber[700],
  },
  variantIcon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: '10px'
  },
  icon: {
    fontSize: 20,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}

const Alert = ({ ...props }) => {

  const {
    alert,
    setAlert
  } = useContext(AppContext)

  const handleClose = () => setAlert()

  return (
      <Snackbar
        styles={ styles.snackbar }
        open={ alert }
        autoHideDuration={4000}
        onClose={ handleClose }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <SnackbarContent
          style={ alert && styles[alert.variant] }
          message={
            <span style={ styles.message }>
              { alert && alert.message }
            </span>
          }
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={ handleClose}>
            <Close style={ styles.icon }/>
          </IconButton>
          ]}
        />
      </Snackbar>
  )
}

export default Alert
