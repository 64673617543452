import { colors } from '@material-ui/core';
import overrides from 'theme/overrides';
import palette from 'theme/palette'
import typography from 'theme/typography'
import { indigo, purple, pink } from '@material-ui/core/colors'
export const primaryDark = '#20233C'
export const lightBlue = '#E9EBF7'

export const muiTheme = {
  palette,
  overrides,
  typography
}
