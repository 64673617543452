import React, { cloneElement } from 'react'
import mobile from 'is-mobile'

const ResponsiveButton = ({ button, ...props }) => {
  const isMobile = mobile()

  const mobileButton =
    button ? cloneElement(button, {
      fullWidth: isMobile ? 'true' : 'false',
      variant: 'outlined'
    }) : button
  return mobileButton ? mobileButton : null
}

export default ResponsiveButton
