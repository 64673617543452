import { useEffect, useState } from 'react'

const usePagination = ({ results }) => {
  // The page page number

  const [page, setPage] = useState(1)
  const [nbPages, setNbPages] = useState(0)
  const [nbHits, setNbHits] = useState(0)
  const [nbStart, setNbStart] = useState(0)
  const [nbFinish, setNbFinish] = useState(0)
  const [nbDisplayed, setNbDisplayed] = useState(0)
  const [hitsPerPage, setHitsPerPage] = useState(0)
  let   [rangeWithDots, setRangeWithDots] = useState([])

  const setPagination = (results) => {
    let { hits, page, nbHits, nbPages, hitsPerPage } = results

    let delta = 3,
      left = page - delta,
      right = page + delta + 1,
      range = [],
      rangeWithDots = [],
      l

    for (let i = 1; i <= nbPages; i++) {
      if (i == 1 || i == nbPages || (i >= left && i < right)) {
        range.push(i)
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1)
        } else if (i - l !== 1) {
          rangeWithDots.push('...')
        }
      }
      rangeWithDots.push(i)
      l = i
    }

    const start = nbHits > 0 ? ((page) * hitsPerPage) + 1 : 0
    const finish = Math.min(nbHits, (page + 1) * hitsPerPage)

    setPage(page+1)
    setNbPages(nbPages)
    setNbHits(nbHits)
    setNbStart(start)
    setNbFinish(finish)
    setHitsPerPage(hitsPerPage)
    setRangeWithDots(rangeWithDots)
  }

  useEffect(() => {
    if(results){
      setPagination(results)
    }
  },[results, hitsPerPage])

  return {
    page,
    nbPages,
    nbHits,
    nbStart,
    nbFinish,
    nbDisplayed,
    hitsPerPage,
    rangeWithDots
  }

}

export default usePagination
