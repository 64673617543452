import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useResource,
  useNavigation
} from 'hooks'
import { validateLink } from 'helpers/validations/link'

const useLinks = ({ id, ...props }) => {

  const {
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: link,
    fetch: fetchLink,
    handleChange,
    update: updateLink,
    create: createLink,
    save: saveLink,
    destroy: deleteLink,
    refresh: refreshLink,
    setResource: setLink,
    showValidationErrors
  } = useResource({
    id: id,
    url: '/api/v1/app/links',
    resourceName: 'link',
    validateFn: validateLink
  })

  return {
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    link,
    fetchLink,
    handleChange,
    saveLink,
    updateLink,
    createLink,
    deleteLink,
    refreshLink,
    setLink,
    showValidationErrors
  }
}

export default useLinks
