import React from 'react'
import {
  Typography,
  Grid,
  Zoom,
  Box
} from '@material-ui/core'
import { indigo, purple } from '@material-ui/core/colors'
import { lightBlue, primaryDark } from 'theme'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  padding: 20px;
  margin: 0;
  background-color: #fff;
`

const StyledDivider = styled(Box)`
  width: 100%;
  margin: 0;
`;

const Caption = styled(Typography)`
  margin-top: 10px;
  text-transform: uppercase;
`

const Text = styled(Typography)`
  color: ${indigo[900]};
`

const Stat = ({ label, value, last }) => (
  <StyledBox
    last={ last }
    display='flex'
    direction='column'
    flexDirection='column'
    alignItems='center'>
    <Text variant='h3'>
      { value }
    </Text>
    <Caption variant='overline' color='textSecondary'>
      { label }
    </Caption>
  </StyledBox>
)

export default Stat
