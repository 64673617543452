import React, { useState, useEffect } from 'react'
import {
  useAlerts,
  useCategories
} from 'hooks'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Box,
  Typography,
  FormControl,
  Grid,
  makeStyles
} from '@material-ui/core'
import TextInput from 'components/inputs/TextInput'
import {
  ExpandMore,
  ChevronRight,
  KeyboardArrowDown
} from '@material-ui/icons'
import SubdirectoryArrowRight from 'resources/images/icons/subdirectory_arrow_right.svg'
import {
  filterParents,
  filterChildren
} from 'helpers/categories'
import _ from 'lodash'
import styled from 'styled-components'
import { sortByParent, isParent, isChild } from 'helpers/categories'
import { syntheticEvent } from 'helpers/utils'
import { get } from 'lodash'

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: 'white'
  }
}))

const StyledTypography = styled(Typography)`
  padding-top: 4px;
  padding-bottom: 4px;
`

const CategorySelect = ({
    value,
    label,
    name='categoryId',
    handleChange,
    ...props
  }) => {

  const [sortedCategories, setSortedCategories] = useState([])
  const [option, setOption] = useState()

  const classes = useStyles()

  const {
    categories,
    fetchCategory,
    fetchCategories,
    isLoaded,
    isLoadeding,
    isEmpty,
    meta,
    page,
    perPage,
    totalCount
  } = useCategories({})

  const handleInputChange = (event, category) => {
    let ev = {}
    if(category){
      setOption({name: category.name, id: category.id, parentId: category.parentId })
      ev = syntheticEvent(category.id, name)
      handleChange(ev, category)
    }
  }

  const setValue = async (value) => {
    let category = await fetchCategory(value)
    setOption({name: category.name, id: category.id, parentId: category.parentId })
  }

  useEffect(() => {
    if(value) setValue(value);

    fetchCategories({
      page: 1,
      perPage: 500
    })
  }, [])

  useEffect(() => {
    if(categories && categories.length > 0){
      setSortedCategories(sortByParent(categories))
    }
  }, [isLoaded, categories])

  let isFetched = sortedCategories.length > 0 &&
    (value && option) || !value

  return(
    <FormControl fullWidth>
      <StyledTypography variant="overline">
        { label }
      </StyledTypography>
      { isFetched &&
        <Autocomplete
          value={ option }
          options={ sortedCategories }
          getOptionLabel={option => isChild(option) ? `- ${option.name}` : option.name }
          onChange={ handleInputChange }
          size='small'
          renderInput={params =>
            <TextField {...params}
              className={ classes.textField }
              placeholder='Category'
              variant="outlined"
            />
          }
        />
      }
    </FormControl>
  )
}

export default CategorySelect
