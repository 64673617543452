import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useResource,
  useLoaders,
  useAlerts
} from 'hooks'

const useDashboard = (props={}) => {

  const { showAlertError, showAlertSuccess } = useAlerts()
  const { showLoading, hideLoading } = useLoaders()

  const [query, setQuery] = useState({})
  const [data, setData] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)

  const fetchDashboard = async (query) => {
    try{
      setQuery(query)
      showLoading()
      let res = await api.get(`/api/v1/app/dashboards`, { params: {
        ...query
      }})
      hideLoading()
      setIsLoaded(true)
      setData(res.data)
      return res.data
    }catch(e){
      showAlertError('An error occurred')
      console.log(e)
    }
  }

  return {
    data,
    fetchDashboard,
    isLoaded,
    query
  }
}

export default useDashboard
