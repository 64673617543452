import React, { useState, useEffect } from 'react'
import {
  Typography,
  Button,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Divider,
  Hidden,
  Box,
  Badge,
  Link
} from '@material-ui/core'
import {
  Close,
  ExpandLess,
  ExpandMore,
  Search,
  Layers,
  PowerSettingsNew,
  SettingsOutlined
} from '@material-ui/icons'
import {
  useMenu,
} from 'hooks'
import Logo from 'resources/images/logos/logo.svg'
import { makeStyles } from '@material-ui/styles'
import {
  MenuItem,
  OnlineStatusAvatar
} from 'components'
import {
  isMember,
  isAdmin,
} from 'helpers/user'
import { MENU_ITEMS } from 'lib/menu'
import styled from 'styled-components'
import { indigo, green } from '@material-ui/core/colors'
import { primaryDark } from 'theme'
import { get } from 'lodash'
const mobile = require('is-mobile')

const drawerWidth = 240

const styles = {
  icon: {
    color: "#333",
    fontSize: '20px'
  }
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: theme.typography.fontWeightMedium,
  },
  button: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontSize: '14px',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  secondaryIcon: {
    marginLeft: 'auto',
    height: 20,
    width: 20
  }
}));

const MenuIconBox = styled(Box)`
  margin-top: 2px;
`

const StyledClose = styled(Close)`
  color: white;
`

const FullName = styled(Typography)`
  text-align: center;
  padding: 10px 0;
`

const StyledList = styled(List)`
  width: 250px;
`

const Drawer = ({
    open,
    authenticated,
    currentUser,
    mobile,
    toggleMenu,
    handleClick,
    handleUpdateAvailability
  }) => {

  const [openAdmin, setOpenAdmin] = useState(false)
  const [openSettings, setOpenSettings] = useState(false)

  const toggleAdminMenu = () => setOpenAdmin(!openAdmin)
  const toggleSettingsMenu = () => setOpenSettings(!openSettings)

  const classes = useStyles();

  const freelancer = get(currentUser, 'data.freelancer')

  return(
      <SwipeableDrawer
        open={ open }
        variant="temporary"
        onOpen={ toggleMenu }
        onClose={ toggleMenu }>
        { currentUser ?
          <Box display='flex' justifyContent='space-between' flexDirection='column' height='100%'>
          <StyledList component="nav">
            { isAdmin(currentUser) &&
              <>
                <MenuItem
                  indent
                  depth={0}
                  text='Admin'
                  icon={ Layers }
                  open={ openAdmin }
                  expandable={ true }
                  handleClick={ toggleAdminMenu }
                />
                <Collapse in={ openAdmin } timeout="auto" unmountOnExit>
                  { MENU_ITEMS['admin'].map((menu, idx) => (
                      <MenuItem
                        indent
                        key={idx}
                        depth={1}
                        value={ menu.value}
                        text={ menu.text }
                        icon={ menu.icon }
                        handleClick={ handleClick }
                      />
                  ))}
                </Collapse>
              </>
            }

          { currentUser &&
            <>
              { MENU_ITEMS['user'].map((menu, idx) => (
                <MenuItem
                  key={idx}
                  value={ menu.value}
                  text={ menu.text }
                  icon={ menu.icon }
                  handleClick={ handleClick }
                />
              ))}

              <MenuItem
                depth={0}
                text='Settings'
                icon={ SettingsOutlined }
                open={ openSettings }
                expandable={ true }
                handleClick={ toggleSettingsMenu }
              />
              <Collapse in={ openSettings } timeout="auto" unmountOnExit>
                <List disablePadding>
                  { MENU_ITEMS['settings'].map((menu, idx) => (
                      <MenuItem
                        key={idx}
                        depth={ 1 }
                        value={ menu.value}
                        text={ menu.text }
                        icon={ menu.icon }
                        handleClick={ handleClick }
                      />
                  ))}
              </List>
            </Collapse>
            </>
          }
        </StyledList>
        <List>
          <Divider />
          <ListItem className={ classes.item } disableGutters>
            <Button
              className={classes.button}
              onClick={() => handleClick('logout')}
            >
            <ListItemIcon>
              <OnlineStatusAvatar
                online={ true }
                user={ currentUser }
              />
            </ListItemIcon>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography variant='caption'>{ currentUser.fullName }</Typography>
              <Typography variant='body2' color='textSecondary'>Sign out</Typography>
            </Box>
          </Button>
          </ListItem>
        </List>
        </Box>
        :
        <StyledList>
          { MENU_ITEMS['public'].map(({value, text}, idx) => (
            <MenuItem
              key={idx}
              value={value}
              text={ text }
              handleClick={ handleClick }
            />
          ))}
        </StyledList>
      }
    </SwipeableDrawer>
  )
}

export default Drawer
