import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useNavigation = ({ url=''}) => {

  const history = useHistory()

  const handleShowClick = (resource) => history.push(`${url}/${resource.id}`)
  const handleEditClick = (resource) => history.push(`${url}/${resource.id}/edit`)
  const handleAddClick = () => history.push(`${url}/new`)

  return {
    handleClick: handleShowClick,
    handleShowClick,
    handleEditClick,
    handleAddClick,

    toShow: handleShowClick,
    toEdit: handleEditClick,
    toAdd: handleAddClick
  }
}

export default useNavigation
