import React from 'react'
import {
  Typography,
  Button,
  Paper,
  Grid,
  Hidden,
  Avatar,
  Box
} from '@material-ui/core'
import { indigo, blue } from '@material-ui/core/colors'
import styled from 'styled-components'

const StyledAvatar = styled(Avatar)`
  height: 60px;
  width: 60px;
  margin: 0 0 20px 0;;
  background-color: ${indigo[700]};
`

const Feature = ({ feature }) => (
  <Grid item xs={12} sm={6}>
    <Box display='flex' justifyContent='flex-start' p={1}>
      <Box p={2}>
        { feature.icon }
      </Box>
      <Box>
        <Typography variant='caption'>
          { feature.title }
        </Typography>
        <Typography variant='body2'>
          { feature.description }
        </Typography>
      </Box>
    </Box>
  </Grid>
)

export default Feature
