import axios from 'axios'
import { BASE_URL } from 'lib/constants'
const headers = { 'Content-Type': 'application/json' }

// on page reload, get the token from localStorage
let token = localStorage.getItem('authToken')
if (token) {
  axios.defaults.headers.common['Authorization'] = `${token}`
}

const api = axios.create({
  baseURL: BASE_URL,
  headers,
  timeout: 200000
})

api.interceptors.response.use(
  (resp) => Promise.resolve(resp.data),
  (error) =>  Promise.reject(error.response)
)

export default api
