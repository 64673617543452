export const isPaid = (user) => !!user.company.paid;

export const hasPaymentMethod = (user) => {
  if(!!user && !!user.company && user.company.stripeCustomerId){
    return true
  }else{
    return false
  }
}

export const isSubscribed = (user) => {
  if(!!user && !!user.company && user.company.paid && user.company.stripePlanId){
    return true
  }else{
    return false
  }
}


export const isMember = (user) =>
  (user && user.status === 'accepted')

export const isPending = (user) =>
  (user && user.status === 'pending')

export const isRejected = (user) =>
  (user && user.status === 'rejected')

export const isAdmin = (user) =>
  (user && user.admin)
