import React from 'react'
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  IconButton
} from '@material-ui/core'
import { indigo, purple, green, amber, pink } from '@material-ui/core/colors'
import { Report, Error, CheckCircle, Close } from '@material-ui/icons'
import styled from 'styled-components'

const StyledListItem = styled(ListItem)`
  border-radius: 4px;
`

const StyledWarningIcon = styled(Report)`
  color: ${amber[500]};
`

const StyledErrorIcon = styled(Error)`
  color: ${purple[500]};
`

const StyledSuccessIcon = styled(CheckCircle)`
  color: ${green[500]};
`

const StyledClosedIcon = styled(Close)`
  color: #999;
  font-size: 16px;
`

const NotificationItem = ({ notification, handleClick }) => (
  <StyledListItem
    button
    dense
    status={ notification.status }
    onClick={(e) => handleClick && handleClick(notification.id) }>
    <ListItemIcon>
      { notification.status === 'warning' && <StyledWarningIcon /> }
      { notification.status === 'error'   && <StyledErrorIcon /> }
      { notification.status === 'success' && <StyledSuccessIcon /> }
    </ListItemIcon>
    <ListItemText primary={
        <Typography
          color='textSecondary'
          variant='body2'>{ notification.text }</Typography>
      }
      />
    <ListItemSecondaryAction>
      <IconButton onClick={(e) => handleClick(notification.id) }>
        <StyledClosedIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </StyledListItem>
)

export default NotificationItem
