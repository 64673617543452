import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import {
  amber,
  indigo,
  green,
  pink,
  blueGrey,
  purple
} from '@material-ui/core/colors'
import styled from 'styled-components'

const StyledAvatar = styled(Avatar)`
  ${({ status }) => (status == 'completed' && `background-color: ${indigo[500]};`)};
  ${({ status }) => (status == 'pending' && `background-color: ${purple[500]};`)};
  ${({ status }) => (status == 'paid' && `background-color: ${green[500]};`)};
  ${({ status }) => (status == 'approved' && `background-color: ${green[500]};`)};
  ${({ status }) => (status == 'accepted' && `background-color: ${green[500]};`)};
  ${({ status }) => (status == 'rejected' && `background-color: ${blueGrey[500]};`)};
  ${({ status }) => (status == 'expired' && `background-color: ${blueGrey[500]};`)};
  ${({ status }) => (status == 'void' && `background-color: ${blueGrey[500]};`)};
  ${({ status }) => (status == 'overdue' && `background-color: ${pink[700]};`)};
`

const StatusAvatar = ({status, children }) => (
  <StyledAvatar
    status={ status }
  >
  { children }
  </StyledAvatar>
)

StatusAvatar.propTypes = {
  status: PropTypes.string
}

export default StatusAvatar
