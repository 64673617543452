import React, { useState, useEffect } from 'react'
import {
  Modal,
  Placeholder
} from 'components'
import { Box } from '@material-ui/core'
import { detect } from 'detect-browser'
import { Public } from '@material-ui/icons'

const BrowserSupport = ({ ...props }) => {

  const [open, setOpen] = useState(false)

  useEffect(() => {
    const browser = detect()
    if(browser.name === 'ie') setOpen(true);
  }, [])

  return(
    <Modal
      open={ open }
      title='Microsoft IE is not a supported browser'
      component={
        <Box pb={4}>
          <Placeholder
            icon={ <Public /> }
            color='purple'
            title='Please upgrade to Microsoft Edge, Chrome or Safari'
            description='Microsoft Internet Explorer (IE) is no longer supported.'
          />
        </Box>
      }
    />
  )
}

export default BrowserSupport
