import React from 'react'
import {
  Box,
  Typography
} from '@material-ui/core'
import { Divider } from 'components'

const SectionHeader = ({title }) => (
  <Box m={5}>
    <center>
      <Typography variant='h2'>
        { title }
      </Typography>
    </center>
  </Box>
)

export default SectionHeader
