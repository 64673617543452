import React, { useState, useEffect } from 'react'
import {
  useAlerts,
  useLinks
} from 'hooks'
import {
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core'
import {
  Modal,
  TextInput,
  BlueBox,
  MultipleAutosuggest,
  Placeholder
} from 'components'
import { Check } from '@material-ui/icons'
import copy from 'copy-to-clipboard'
import {
  CLIENT_URL
} from 'lib/constants'

const LinkModal = ({
    open,
    handleCloseClick,
    documentIds,
    ...props
  }) => {

  const {
    showAlertSuccess,
    showAlertError
  } = useAlerts()

  const {
    isLoading,
    isLoaded,
    isValid,
    link,
    createLink,
    handleChange,
    showValidationErrors,
    setLink
  } = useLinks({})

  let [shareLink, setShareLink] = useState()

  const handleSubmit = async () => {
    let newLink = await createLink(link)
    let url = newLink.url
    setShareLink(url)
    showAlertSuccess("Send this link to share files")
    setLink({ emails: null })
  }

  const handleCopyClick = () => {
    copy(shareLink)
    showAlertSuccess("Link copied to clipboard!")
  }

  useEffect(() => {
    setLink({
      ...link,
      documentIds
    })
  },[documentIds])

  useEffect(() => {
    if(open){
      handleSubmit()
    }
  },[open])

  return(
    <Modal
      open={ open }
      handleClose={ handleCloseClick }
      title='Share files'
      component={
        shareLink &&
        <Placeholder
          color='green'
          icon={ <Check />}
          title={ <BlueBox p={2}>{ shareLink }</BlueBox>}
          description='(Use this link to share files)'
        />

      }
      actions={
        [
          shareLink &&
          <Button
            fullWidth
            color='primary'
            variant='contained'
            onClick={ handleCopyClick }>
            Copy share URL
          </Button>
        ]
      }
      />
    )
}

export default LinkModal
