import React, { useEffect, useState, useContext } from 'react'
import { useAuth } from 'hooks'
import { AppContext } from 'context'
import { Route } from 'react-router'
import { Redirect } from 'react-router-dom'
import { isAdmin } from 'helpers/user'

const AdminRoute = ({
    component: Component,
    redirect,
    ...rest
  }) => {

  const {
    authenticated,
    currentUser
  } = useContext(AppContext)

  const {
    fetchMe
  } = useAuth()

  const [restricted, setRestricted] = useState(false)

  useEffect(() => {
    if(!authenticated) fetchMe();
  }, [])

  useEffect(() => {
    if(currentUser){
      setRestricted(!isAdmin(currentUser))
    }
  }, [currentUser])

  return(
    <Route {...rest} render={(props) => (
      restricted ? <Redirect to='/' /> : <Component {...props} />
    )}
    />
  )
}


export default AdminRoute
