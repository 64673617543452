import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useResource,
  useNavigation,
  useLoaders,
  useAlerts
} from 'hooks'
import { validateCompany } from 'helpers/validations/company'


const useAdminCompanys = ({ id, ...props }) => {

  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    resourceId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource,
    resources,
    fetch,
    fetchAll,
    update,
    create,
    save,
    destroy,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    refresh: refreshCompany,
    refreshAll: refreshCompanys,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    setResource: setCompany,
    showValidationErrors
  } = useResource({
    id: id,
    url: '/api/v1/admin/companies',
    resourceName: 'company',
    validateFn: validateCompany
  })

  const updateCompanyStatus = async (companyId, status) => {
    try{
      showLoading()
      await api.put(`/api/v1/admin/companies/${companyId}/update_status`, {
        status
      })
      showAlertSuccess('Company successfully updated')
    }catch(e){
      console.log(e)
      showAlertError('There was an error updating the status')
    }
  }

  return {
    companyId: resourceId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    company: resource,
    companies: resources,
    fetchCompany: fetch,
    fetchCompanys: fetchAll,
    saveCompany: save,
    updateCompany: update,
    createCompany: create,
    deleteCompany: destroy,
    uploadFile,
    deleteFile,
    handleChange,
    handleChangePage,
    updateCompanyStatus,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    refreshCompany,
    refreshCompanys,
    setCompany,
    showValidationErrors
  }
}

export default useAdminCompanys
