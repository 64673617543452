import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useResource,
  useNavigation,
  useLoaders,
  useAlerts
} from 'hooks'
import { validateUpdateUser } from 'helpers/validations/user'

const useUsers = ({ ...props }) => {

  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    resourceId: userId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: user,
    resources: users,
    fetch: fetchUser,
    fetchAll: fetchUsers,
    update: updateUser,
    create: createUser,
    save: saveUser,
    destroy: destroyUser,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    refresh: refreshUser,
    refreshAll: refreshUsers,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    showValidationErrors
  } = useResource({
    id: "me",
    url: '/api/v1/app/users',
    resourceName: 'user',
    validateFn: validateUpdateUser
  })


  const fetchMe = async () => fetchUser('me')

  const updateUserStatus = async (userId, status) => {
    try{
      showLoading()
      await api.put(`/api/v1/app/users/${userId}/update_status`, {
        status
      })
      showAlertSuccess('Account successfully updated')
    }catch(e){
      showAlertError('There was an error updating the status')
    }
  }

  const addUserPayment = async (stripeToken, last4) => {
    try{
      showLoading()
      await api.post(`/api/v1/app/users/add_payment`, {
        stripeToken,
        last4Digits: last4
      })
      showAlertSuccess('Account successfully updated')
    }catch(e){
      showAlertError('There was an error adding the payment method')
    }
    hideLoading()
  }

  const removeUserPayment = async () => {
    try{
      showLoading()
      await api.get(`/api/v1/app/users/remove_payment`)
      showAlertSuccess('Payment method successfully removed')
    }catch(e){
      showAlertError('There was an error removing the payment method')
    }
  }

  const inviteUser = async (user) => {
    try{
      showLoading()
      await api.post(`/api/v1/app/users/invite_user`, {
        user
      })
      showAlertSuccess('User was successfully invited')
      hideLoading()
    }catch(e){
      showAlertError('There was an error inviting the user')
      hideLoading()
    }
  }

  return {
    userId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    currentUser: user,
    user,
    users,
    fetchMe,
    fetchUser,
    fetchUsers,
    saveUser,
    updateUser,
    createUser,
    uploadFile,
    deleteFile,
    addUserPayment,
    removeUserPayment,
    handleChange,
    handleChangePage,
    updateUserStatus,
    inviteUser,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    refreshUser,
    refreshUsers,
    showValidationErrors
  }
}

export default useUsers
