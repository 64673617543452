import React from 'react'
import {
  Box,
  Container,
  Paper,
  Typography,
  AppBar,
  Button,
  IconButton,
  Grow,
  Slide,
  Grid
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import styled from 'styled-components'

const StyledPaper = styled(Paper)`
  z-index: 1300;
  background-color: #fff;
  top: 0px;
  left: 0px;
  position: fixed;
  padding: 10px 0;
  width: 100%;
`

const StyledContainer = styled(Container)`
  padding-top: 5px;
`
const StyledButton = styled(IconButton)`
  margin-top: 0px;
`

const Toolbar = ({
  open,
  handleClose,
  children,
  ...props
  }) => (
  <Slide in={ open } direction='down'>
    <StyledPaper square elevation={2}>
      <StyledContainer maxWidth='xl'>
        <Grid container spacing={1}>
          <Grid item xs={1} sm={1} />
          <Grid item xs={10} sm={10}>
            <Box display='flex' justifyContent='center' flexWrap='wrap' width='100%'>
              { children }
            </Box>
          </Grid>
          <Grid item xs={1} sm={1}>
            <Box display='flex' width='100%' justifyContent='flex-end'>
              <StyledButton onClick={ handleClose }>
                <Clear />
              </StyledButton>
            </Box>
          </Grid>
        </Grid>
      </StyledContainer>
    </StyledPaper>
  </Slide>
)


export default Toolbar
