import { isEmpty } from './validate'

export const validateTemplate = (template) => {
  let isValid = true
  let messages = []
  let warnings = []

  let validation = {
    isValid,
    messages,
    warnings
  }

  return  validation
}
