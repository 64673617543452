import React, { useEffect, useState, useContext } from 'react'
import { AppContext } from 'context'
import { Route } from 'react-router'
import { Redirect } from 'react-router-dom'

const AuthRoute = ({
    component: Component,
    redirect,
    ...rest
  }) => {

  const { authenticated } = useContext(AppContext)

  return(
    <Route {...rest} render={(props) => (
      authenticated === false ? <Redirect to='/login' /> : <Component {...props} />
    )} />
  )
}

export default AuthRoute
