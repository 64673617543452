import React from 'react'
import { Zoom, Box } from '@material-ui/core'
import { indigo, purple, blue } from '@material-ui/core/colors'
import styled from 'styled-components'

const StyledDivider = styled(Box)`
  border-bottom: 3px solid ${purple[500]};
  width: 240px;
  margin-top: 30px;
  margin-bottom: 40px;
  ${(({align}) => align === 'left' && 'transform-origin:  0% 50%;')};
`;

const Divider = () => (
  <Zoom in={true}>
    <StyledDivider />
  </Zoom>
)

export default Divider;
