import React, { useContext, useState, useEffect } from 'react'
import {
  useAuth,
  useAlerts,
  useSearch
} from 'hooks'
import {
  AppContext
} from 'context'
import { useHistory } from 'react-router'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Box,
  Typography,
  FormControl,
  Grid,
  TextField,
  InputAdornment,
  makeStyles
} from '@material-ui/core'
import TextInput from 'components/inputs/TextInput'
import {
  Search
} from '@material-ui/icons'
import _ from 'lodash'
import styled from 'styled-components'
import { syntheticEvent } from 'helpers/utils'
import { get } from 'lodash'
import mobile from 'is-mobile'

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: '#fff',
    opacity: '0.90',
    borderRadius: '4px',
    minWidth: mobile() ? '180px' : '320px'
  }
}))

const StyledTypography = styled(Typography)`
  padding-top: 4px;
  padding-bottom: 4px;
`

const AutocompleteSearch = ({ ...props }) => {

  const classes = useStyles()
  const history = useHistory()
  const {
    page,
    isLoaded,
    isLoading,
    keywords,
    resources: documents,
    setResources,
    nbHits,
    nbPages,
    nbStart,
    nbFinish,
    query,
    setQuery,
    handleSearch,
    searchDocuments,
    handleChange,
    handleFacetChange,
    handleClear,
    handleClearSearch,
    handleKeyPress,
    algoliaSearchToken,
    setAlgoliaSearchToken
  } = useSearch({
    keywords: '',
    hitsPerPage: 20,
    filters: ``,
    facets: []
  })

  const {
    algoliaToken
  } = useContext(AppContext)

  const {
    authenticated,
    fetchAlgoliaToken
  } = useAuth()

  const { showAlertError } = useAlerts()

  const handleTextChange = (event) => {
    let { value } = event.target
    searchDocuments({keywords: value, page: 1 })
  }

  const handleInputChange = (ev, document) => {
    if(document){
      history.push(`/app/documents/${document.id}`)
    }
    setResources([])
  }

  useEffect(() => {
    fetchAlgoliaToken()
  },[])

  useEffect(() => {
    if(algoliaToken){
      setAlgoliaSearchToken(algoliaToken)
    }
  }, [algoliaToken])

  return(
    <Autocomplete
      options={ documents }
      getOptionLabel={ option => option.title }
      groupBy={option => option.category_name}
      onChange={ handleInputChange }
      size='small'
      renderInput={params =>
        <TextField
          {...params}
          color='primary'
          onChange={ handleTextChange }
          className={ classes.textField }
          placeholder='Search...'
          variant="outlined"
        />
      }
    />
  )
}

export default AutocompleteSearch
