import React from 'react'
import {
  Layers,
  Search,
  Folder,
  Cloud
} from '@material-ui/icons'

export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const STRIPE_PRIVATE_KEY = process.env.REACT_APP_STRIPE_PRIVATE_KEY

export const MAX_SIZE_BYTES = 10485760 //10mb
export const MAX_FILES_LIMIT = 10

export const FEATURED_CATEGORIES = [
  {label: process.env.REACT_APP_CAT1_LABEL, id: process.env.REACT_APP_CAT1_ID},
  {label: process.env.REACT_APP_CAT2_LABEL, id: process.env.REACT_APP_CAT2_ID},
  {label: process.env.REACT_APP_CAT3_LABEL, id: process.env.REACT_APP_CAT3_ID}
]

export const DASHBOARD_ITEMS = [
  {id: 'documents', primary: "Search", secondary: 'Browse families', path: '/app/documents', icon: <Search />},
  {id: 'upload', primary: "Upload",  secondary: 'Upload files', path: '/app/upload', icon: <Cloud />}
]

export const REVIT_VERSIONS = [
  {value: '2016', label: '2016'},
  {value: '2017', label: '2017'},
  {value: '2018', label: '2018'},
  {value: '2019', label: '2019'},
  {value: '2020', label: '2020'}
]

export const DOCUMENT_CATEGORIES = [
  {value: 'all', label: 'All'}
]

export const USER_STATES = [
  {value: 'all', label: 'All'},
  {value: 'pending', label: 'Pending'},
  {value: 'accepted', label: 'Accepted'},
  {value: 'rejected', label: 'Rejected'}
]

export const USER_TRANSITIONS = [
  {value: 'pending', label: 'Pending'},
  {value: 'accepted', label: 'Accepted'},
  {value: 'rejected', label: 'Rejected'}
]

export const USER_TYPES = [
  {value: 'all', label: 'All'},
  {value: 'user', label: 'User'},
  {value: 'admin', label: 'Admin'}
]


export const YESNO = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

export const STATES = [
  {value: "Alabama", label: "Alabama"},
  {value: "Alaska", label: "Alaska"},
  {value: "Arizona", label: "Arizona"},
  {value: "Arkansas", label: "Arkansas"},
  {value: "California", label: "California"},
  {value: "Colorado", label: "Colorado"},
  {value: "Connecticut", label: "Connecticut"},
  {value: "Delaware", label: "Delaware"},
  {value: "Florida", label: "Florida"},
  {value: "Georgia", label: "Georgia"},
  {value: "Hawaii", label: "Hawaii"},
  {value: "Idaho", label: "Idaho"},
  {value: "Illnois", label: "Illnois"},
  {value: "Indiana", label: "Indiana"},
  {value: "Iowa", label: "Iowa"},
  {value: "Kansas", label: "Kansas"},
  {value: "Kentucky", label: "Kentucky"},
  {value: "Louisiana", label: "Louisiana"},
  {value: "Maine", label: "Maine"},
  {value: "Maryland", label: "Maryland"},
  {value: "Massachusetts", label: "Massachusetts"},
  {value: "Michigan", label: "Michigan"},
  {value: "Minnesota", label: "Minnesota"},
  {value: "Mississippi", label: "Mississippi"},
  {value: "Missouri", label: "Missouri"},
  {value: "Montana", label: "Montana"},
  {value: "Nebraska", label: "Nebraska"},
  {value: "Nevada", label: "Nevada"},
  {value: "New Hampshire", label: "New Hampshire"},
  {value: "New Jersey", label: "New Jersey"},
  {value: "New Mexico", label: "New Mexico"},
  {value: "New York", label: "New York"},
  {value: "North Carolina", label: "North Carolina"},
  {value: "North Dakota", label: "North Dakota"},
  {value: "Ohio", label: "Ohio"},
  {value: "Oklahoma", label: "Oklahoma"},
  {value: "Oregon", label: "Oregon"},
  {value: "Pennsylvania", label: "Pennsylvania"},
  {value: "Rhode Island", label: "Rhode Island"},
  {value: "South Carolina", label: "South Carolina"},
  {value: "South Dakota", label: "South Dakota"},
  {value: "Tennessee", label: "Tennessee"},
  {value: "Texas", label: "Texas"},
  {value: "Utah", label: "Utah"},
  {value: "Vermont", label: "Vermont"},
  {value: "Virginia", label: "Virginia"},
  {value: "Washington", label: "Washington"},
  {value: "West Virginia", label: "West Virginia"},
  {value: "Wisconsin", label: "Wisconsin"},
  {value: "Wyoming", label: "Wyoming"}
]

export const INVITE_CODES = [
  '20!S3877',
  '20!VCA',
  '20!ACM',
  '20!IS',
  '20!IG',
  '20!RTKL',
  '20!DJA',
  '20!WMA',
  '20!SG',
  '20!G',
  '20!GUEST'
]
