import React, { createRef, useState, useEffect } from 'react'
import Select from 'react-select'
import {
  Grow,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  ClickAwayListener,
  Grid,
  Container,
  Badge,
  Checkbox,
  Typography,
  Paper
} from '@material-ui/core'
import Sticky from 'react-sticky-fill'
import { TextInput } from 'components'
import { Add, Clear, ExpandMore, ExpandLess } from '@material-ui/icons'
import useFacets from 'hooks/common/useFacets'
import { getReactDimensions } from 'helpers/reactSize'
import { StyledChip } from './styles'
import styled from 'styled-components'


const StyledPaper = styled(Paper)`
  position: absolute;
  z-index: 999;
  max-height: 360px;
  overflow-y: scroll;
  ${({pos}) => `top: ${pos.y}; left: ${pos.x}; width: ${pos.width}px;` }
`

const StyledFilterBox = styled(Box)`
  background-color: white;
`
const Label = styled(Typography)`
  text-transform: uppercase;
`

const StyledBox = styled(Box)`
  height: 100%;
  max-height: 360px;
  overflow-y: scroll;
`

const PopoverFilter = ({
    facetName,
    values=[],
    label,
    filters='',
    placeholder,
    selectFilter
  }) => {

  const ref = createRef()
  const [expanded, setExpanded] = useState(false)
  const [position, setPosition] = useState({})

  const  {
    isLoaded,
    query,
    options,
    handleSearchFacets,
    handleChange,
    handleClear
  } =  useFacets({ facetName, facetQuery: '', filters })

  const renderHTML = (option) => ({__html: option['label']})
  const handleToggleExpand = () => {
    if(!expanded){
      handleSearchFacets(query)
    }
    setExpanded(!expanded)

    if(ref && ref.current){
      let pos = getReactDimensions(ref)
      setPosition(pos)
    }
  }

  const handleClick = option => {
    selectFilter(facetName, option.value)
  }

  return (
    <>
      { label &&
        <List disablePadding ref={ref}>
          <ListItem button dense onClick={ handleToggleExpand }>
            <ListItemText primary={ label } />
            <ListItemSecondaryAction>
              <IconButton onClick={ handleToggleExpand }>
                { expanded ? <ExpandLess /> : <ExpandMore /> }
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      }

      { isLoaded && expanded &&
        <ClickAwayListener onClickAway={ handleToggleExpand }>
          <StyledPaper pos={ position }>
            <Sticky>
              <StyledFilterBox p={1}>
                <TextInput
                  autoFocus={ true }
                  value={query}
                  placeholder={ `search...`}
                  handleChange={ handleChange }
                  handleClearClick={ handleClear }
                  margin='dense'
                />
              </StyledFilterBox>
            </Sticky>
            <List>
             {options.map((option, i) => (
               <ListItem
                  dense
                  disableGutters
                  key={i}
                  button
                  onClick={()=> handleClick(option)}>
                  <ListItemIcon>
                    <Checkbox
                      value={true}
                      checked={ values.includes(option.value) ? true : false }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <div dangerouslySetInnerHTML={ renderHTML(option) } />
                    }
                  />
                  <ListItemSecondaryAction>
                    <StyledChip color='secondary' label={option.count} size='small'/>
                  </ListItemSecondaryAction>
               </ListItem>
             ))}
             {!options || options.length <= 0 &&
               <ListItem>
                 <ListItemText secondary='No results' />
              </ListItem>
             }
          </List>
        </StyledPaper>
      </ClickAwayListener>
    }
  </>
  )
}

export default PopoverFilter
