import React from 'react'
import { useHistory } from 'react-router'
import { List } from '@material-ui/core'
import { ListTabItem } from 'components'
import {
  Person,
  People,
  CreditCard
} from '@material-ui/icons'

const UserTabs = ({ step, ...props }) => {

  const history = useHistory()

  const handleClick = (route) =>
    history.push(route)

  return(
    <List>
      <ListTabItem
        step={ step }
        icon={ <Person /> }
        value={ 1 }
        primary='Account'
        secondary='Account details'
        handleClick={() => handleClick(`/app/users/me/edit`) }
      />
      <ListTabItem
        step={ step }
        value={ 2 }
        icon={ <People /> }
        primary='Team'
        secondary='Invite team members'
        handleClick={() => handleClick('/app/users') }
      />
      <ListTabItem
        step={ step }
        value={ 3 }
        icon={ <CreditCard /> }
        primary='Subscription'
        secondary='Update plan'
        handleClick={() => handleClick('/app/payments') }
      />
    </List>
  )
}

export default UserTabs
