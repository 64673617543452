import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useResource,
  useNavigation,
  useLoaders,
  useAlerts
} from 'hooks'
import { validateUpdateUser } from 'helpers/validations/user'


const useAdminUsers = ({ id, ...props }) => {

  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    resourceId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource,
    resources,
    fetch,
    fetchAll,
    update,
    create,
    save,
    destroy,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    refresh: refreshUser,
    refreshAll: refreshUsers,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    setResource: setUser,
    showValidationErrors
  } = useResource({
    id: id,
    url: '/api/v1/admin/users',
    resourceName: 'user',
    validateFn: validateUpdateUser
  })

  const updateUserStatus = async (userId, status) => {
    try{
      showLoading()
      await api.put(`/api/v1/admin/users/${userId}/update_status`, {
        status
      })
      showAlertSuccess('User successfully updated')
    }catch(e){
      console.log(e)
      showAlertError('There was an error updating the status')
    }
  }

  return {
    userId: resourceId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    user: resource,
    users: resources,
    fetchUser: fetch,
    fetchUsers: fetchAll,
    saveUser: save,
    updateUser: update,
    createUser: create,
    deleteUser: destroy,
    uploadFile,
    deleteFile,
    handleChange,
    handleChangePage,
    updateUserStatus,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    refreshUser,
    refreshUsers,
    setUser,
    showValidationErrors
  }
}

export default useAdminUsers
