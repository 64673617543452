import {
  isEmpty,
  isEmail
} from './validate'

export const validateContact = (contact) => {
  let isValid = true
  let messages = []
  let warnings = []

  if(isEmpty(contact.name)){
    isValid = false
    messages.push('Name is required')
  }

  if(isEmpty(contact.email)){
    isValid = false
    messages.push('Email is required')
  }

  if(isEmpty(contact.company)){
    isValid = false
    messages.push('Please tell us the name of your company')
  }

  let validation = {
    isValid,
    messages,
    warnings
  }

  return  validation
}
