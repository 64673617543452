import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { SuspenseSpinner } from 'components'
import { BrowserRouter } from 'react-router-dom'
import { STRIPE_PRIVATE_KEY } from 'lib/constants'
import { StripeProvider } from 'react-stripe-elements'
import App from 'App'
import HttpsRedirect from 'react-https-redirect'
import { AppProvider } from 'context'
import { StylesProvider } from '@material-ui/styles'

const rootElement = document.getElementById('root');

ReactDOM.render(
  <HttpsRedirect>
    <Suspense fallback={ <SuspenseSpinner /> }>
      <BrowserRouter>
        <StripeProvider apiKey={ STRIPE_PRIVATE_KEY }>
          <AppProvider>
            <StylesProvider injectFirst>
              <App />
            </StylesProvider>
          </AppProvider>
        </StripeProvider>
      </BrowserRouter>
    </Suspense>
  </HttpsRedirect>, rootElement)
