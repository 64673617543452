import React from 'react'
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography
} from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'
import { Close } from '@material-ui/icons'
import styled from 'styled-components'
import { primaryDark } from 'theme'
import mobile from 'is-mobile'

const StyledDialog = styled(Dialog)`
  z-index: 1500;
`

const StyledDialogTitle = styled(DialogTitle)`
  background-color: ${primaryDark};
  color: white;
  margin-bottom: 10px;
  height: 52px;
`

const StyledIcon = styled('div')`
  margin: -22px auto auto auto;
  padding: 0;
`

const Title = styled(Typography)`
  color: white;
`

const CloseIcon = styled(Close)`
  color: white;
`

const CloseButton = styled(IconButton)`
  color: white;
  margin: 0;
  padding: 0;
`

const Modal = ({
  open,
  handleClose,
  title,
  subtitle,
  actions,
  component,
  maxWidth='sm',
  fullScreen
}) => (
  <StyledDialog
    fullWidth
    maxWidth={ maxWidth }
    fullScreen={ mobile() || fullScreen === true ? true : false }
    open={ open }
    onClose={ handleClose && handleClose }>
    <StyledDialogTitle onClose={ handleClose }>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' flexDirection='row'>
          <Title variant="h5">{ title }</Title>
        </Box>
        <Box>
          { handleClose &&
            <CloseButton onClick={ handleClose }>
              <CloseIcon />
            </CloseButton>
          }
        </Box>
      </Box>
    </StyledDialogTitle>
    <DialogContent>
      { subtitle && <Typography variant="body1">{ subtitle }</Typography> }
      { component }
    </DialogContent>
    { actions &&
      <DialogActions>{ actions }</DialogActions>
    }
  </StyledDialog>
)

export default Modal
