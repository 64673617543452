import React, { useState } from 'react';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import styled from 'styled-components'

export const StyledList = styled(List)`
  padding: 0;
`

const Panel = ({ label, children }) => {
  const [expanded, setExpanded] = useState(false)
  const handleToggleExpand = () => setExpanded(!expanded)

  return (
    <>
    { label &&
      <>
        <StyledList>
          <ListItem button onClick={ handleToggleExpand }>
            <ListItemText primary={ label } />
            <ListItemSecondaryAction>
              <IconButton onClick={ handleToggleExpand }>
                { expanded ? <ExpandLess /> : <ExpandMore /> }
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </StyledList>
        <Divider />
      </>
    }
    { expanded && children }
  </>
  );
}

export default Panel
