import { indigo } from '@material-ui/core/colors'

export const selectStyles = {
  select: styles => ({
    ...styles,
  }),
  control: styles => ({
    ...styles,
    width: '100%',
    color: '#000 !important',
    fontSize: '13px',
    marginTop: '2px',
    fontFamily: 'Roboto',
    padding: '0',
    borderRadius: '4px !important'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: 'white',
    fontSize: '13px',
    fontFamily: 'Roboto',
    color: 'black',
    ':hover': {
      backgroundColor: indigo[500],
      color: 'white',
    },
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontSize: '13px',
    fontFamily: 'Roboto'
  }),
  input: styles => ({
    ...styles
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: '13px',
    fontFamily: 'Roboto',
    color: '#666',
  }),
  singleValue: styles => ({
    ...styles,
    color: '#222222',
    borderRadius: '4px'
  }),
  multiValue: styles => ({
    ...styles,
    padding: '2px 6px',
    borderRadius: '4px',
    backgroundColor: indigo[500],
  }),
  multiValueLabel: styles => ({
    ...styles,
    backgroundColor: indigo[500],
    color: 'white',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: 'white',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: indigo[500],
      color: 'white',
    },
  })
}
