import palette from '../palette';

export default {
  root: {
    fontSize: '11px',
    textTransform: 'uppercase'
  },
  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.text.secondary
    }
  }
};
