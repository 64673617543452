import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Slider, Typography } from '@material-ui/core'
import { Panel } from 'components'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});


const RangeSlider = ({ label, name, min=0, max=250, value=[50,150], handleChange}) => {
  const classes = useStyles();

  return (
    <Panel label={ label }>
      <Box p={2} className={classes.root}>
        <Slider
          step={10}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          min={min}
          max={max}
        />
      </Box>
    </Panel>
  );
}

export default RangeSlider
