import React from 'react'
import styled from 'styled-components'

const CSSWrapper = styled('div')`
  .currently-typing-wrapper {
    padding: 12px;
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    align-content: center;
    justify-items: center;
    height: 50px;
  }
  .container-dot {
    padding-left: 15px;
    display: inline-block;
  }
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    display: inline-block;
    background-color: #B4B5B9;
    animation: 1.2s typing-dot ease-in-out infinite;
  }
  .dot:nth-of-type(2) {
    animation-delay: 0.15s;
  }
  .dot:nth-of-type(3) {
    animation-delay: 0.25s;
  }
  @keyframes typing-dot {
    15% {
      transform: translateY(-35%);
      opacity: 0.5;
    }
    30% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
`

const Typing = ({ ...props }) => (
  <CSSWrapper>
    <div class="currently-typing-wrapper">
      <div class="container-dot">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>
  </CSSWrapper>
)

export default Typing
