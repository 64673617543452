import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

const useQuery = ({ handleSearch, ...props }) => {

  const location = useLocation()
  const searchParams = queryString.parse(location.search)

  const [isLoaded, setIsLoaded] = useState(false)
  const [query, setQuery] = useState(searchParams)

  const handleChange = (ev) => {
    const { name } = ev.target
    let value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value
    let newQuery = {
      ...query,
      [name]: value
    }
    setQuery(newQuery)
    if(value === ''){
      //handleSearch(newQuery)
    }
  }

  const handleKeyDown = (event) => {
    switch(event.key){
      case 'Enter':
        //handleSearch(query)
        return
      default:
        return
    }
  }

  const handleClear = () => {
    setQuery({ keywords: ''})
    handleSearch({
      keywords: '',
      ...query
    })
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return(() => {
      document.removeEventListener("keydown", handleKeyDown)
    })
  }, [])

  return {
    query,
    isLoaded,
    handleClear,
    handleChange,
    setQuery
  }
}

export default useQuery
