import React, { cloneElement } from 'react'
import {
  Box,
  Grid,
  Grow,
  ButtonGroup,
  Typography
} from '@material-ui/core'
import { ResponsiveButton } from 'components'
import { indigo } from '@material-ui/core/colors'
import styled from 'styled-components'
import mobile from 'is-mobile'

const Header = styled(Typography)`
  margin: 5px 0 10px 0;
  line-height: 1.0em;
`

const Heading = ({ header, width=8, subheader, buttons=[]}) => {
  const isMobile = mobile()
  return(
    <Box width='100%' display='flex' justifyContent='space-between' flexWrap='wrap'>
      <Box>
        <Typography variant="overline">
          { subheader }
        </Typography>
        <Header variant="h3">
          { header }
        </Header>
      </Box>
      <Box
        width={ isMobile ? '100%' : null}
        my={1}
        ml={ isMobile ? 0 : 1 }
        display='flex'
        justifyContent={'flex-end'}>
        { buttons &&
          buttons
            .filter(b => b != null)
            .map((button, idx) =>
              <Box
                key={ idx }
                width='100%'
                display='flex'
                flexWrap='wrap'
                justifyContent='space-between'
                mx={1}>
                <Box py={1} width='100%'>
                  <ResponsiveButton button={ button } />
                </Box>
              </Box>
          )}
      </Box>
  </Box>
  )
}

export default Heading
