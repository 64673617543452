import React from 'react'
import Chip from '@material-ui/core/Chip'
import {
  indigo,
  amber,
  green,
  purple,
  pink,
  blueGrey
} from '@material-ui/core/colors'
import styled from 'styled-components'

const StyledTag = styled(Chip)`
  color: white;
  font-size: 10px;
  width: 80px;
  margin: 5px 10px 5px 0px;
  text-transform: uppercase;

  ${({ status }) => (status == 'draft' && `background-color: ${amber[700]};`)};
  ${({ status }) => (status == 'sourced' && `background-color: ${amber[700]};`)};
  ${({ status }) => (status == 'followed_up' && `background-color: ${amber[700]};`)};
  ${({ status }) => (status == 'followedUp' && `background-color: ${amber[700]};`)};
  ${({ status }) => (status == 'opened' && `background-color: ${amber[700]};`)};
  ${({ status }) => (status == 'messaged' && `background-color: ${amber[700]};`)};

  ${({ status }) => (status == null && `background-color: ${purple[500]};`)};
  ${({ status }) => (status == 'submitted' && `background-color: ${purple[500]};`)};
  ${({ status }) => (status == 'available' && `background-color: ${purple[500]};`)};
  ${({ status }) => (status == 'pending' && `background-color: ${purple[500]};`)};
  ${({ status }) => (status == 'phone_screened' && `background-color: ${purple[500]};`)};
  ${({ status }) => (status == 'technical_screened' && `background-color: ${purple[500]};`)};

  ${({ status }) => (status == 'paid' && `background-color: ${green[500]};`)};
  ${({ status }) => (status == 'submitted' && `background-color: ${green[500]};`)};
  ${({ status }) => (status == 'completed' && `background-color: ${green[500]};`)};
  ${({ status }) => (status == 'approved' && `background-color: ${green[500]};`)};
  ${({ status }) => (status == 'accepted' && `background-color: ${green[500]};`)};

  ${({ status }) => (status == 'closed' && `background-color: ${blueGrey[500]};`)};
  ${({ status }) => (status == 'rejected' && `background-color: ${blueGrey[500]};`)};
  ${({ status }) => (status == 'expired' && `background-color: ${blueGrey[500]};`)};
  ${({ status }) => (status == 'client_rejected' && `background-color: ${blueGrey[500]};`)};
  ${({ status }) => (status == 'archived' && `background-color: ${blueGrey[500]};`)};
  ${({ status }) => (status == 'unavailable' && `background-color: ${blueGrey[500]};`)};
  ${({ status }) => (status == 'void' && `background-color: ${blueGrey[500]};`)};

  ${({ status }) => (status == 'overdue' && `background-color: ${pink[700]};`)};
`

const StatusTag = ({status}) => (
  <StyledTag
    clickable
    status={ status }
    label={ status }
  />
)

export default StatusTag
