import algoliasearch from 'algoliasearch/lite'

const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID
const ALGOLIA_SEARCH_API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
const ALGOLIA_DOCUMENTS_INDEX=process.env.REACT_APP_ALGOLIA_DOCUMENTS_INDEX
export const ALGOLIA_PUBLIC_SEARCH_TOKEN=process.env.REACT_APP_ALGOLIA_PUBLIC_SEARCH_TOKEN

export const algoliaIndex = (token) => {
  let client = algoliasearch(ALGOLIA_APP_ID, token)
  let index = client.initIndex(ALGOLIA_DOCUMENTS_INDEX)
  return index
}
