import React from 'react'
import { Button } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { Modal } from 'components'
import { indigo } from '@material-ui/core/colors'

const DeleteWarningModal = ({
    open,
    handleCloseClick,
    handleDeleteClick
  }) => (
  <Modal
    open={ open }
    title="Are you sure?"
    subtitle='Please confirm or cancel'
    actions={
      [
        <Button
          color='primary'
          onClick={ handleDeleteClick }>
          Confirm
        </Button>,
        <Button
          color='primary'
          onClick={ handleCloseClick }>
          Cancel
        </Button>
    ]
    }
    handleClose={ handleCloseClick }
  />
)

export default DeleteWarningModal
