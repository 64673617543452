import React from 'react'
import { Chip } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { indigo } from '@material-ui/core/colors'
import styled from 'styled-components'

const StyledChip = styled(Chip)`
  color: white;
  margin: 10px 10px 0 0;
  background-color: ${indigo[700]};
`

const Skill = ({
    skill,
    approved,
    handleClick,
    handleDeleteClick,
    isEditing,
  ...props
  }) => (
  isEditing ?
    <StyledChip
      clickable
      color='primary'
      label={ skill.name }
      onClick={ (e) => handleClick  && handleClick(skill) }
      onDelete={ (e) => isEditing && handleDeleteClick(skill) }
      deleteIcon={ <Clear /> }
    /> :
    <StyledChip
      clickable
      color='primary'
      label={ skill.name }
      onClick={() => handleClick  && handleClick(skill)}
    />
)

export default Skill
