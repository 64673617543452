import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import {
  Box,
  Button,
  Badge,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Grid,
  Container,
  Checkbox,
  Typography,
  Divider
} from '@material-ui/core'
import Sticky from 'react-sticky-fill'
import { TextInput } from 'components'
import { Add, Clear, ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { algoliaIndex } from 'services/algolia'
import { StyledContainer, StyledChip, StyledList } from './styles'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  max-height: 360px;
  overflow-y: scroll;
`

const Input = styled(Box)`
  background-color: white;
`

const SelectFacet = ({
    facetName,
    token,
    values=[],
    label,
    placeholder,
    toggleFacet,
    selectFacet,
    removeFacet
  }) => {

  const [query, setQuery] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [options, setOptions] = useState([{}])

  const searchFacets = async (facetQuery) => {
    let index = algoliaIndex(token)
    let results = await index.searchForFacetValues({
      facetName,
      facetQuery,
    })
    let filterOptions = buildOptions(results)
    setOptions(filterOptions)
  }

  const buildOptions = results =>
    results.facetHits.map(fh => ({
      label: fh.highlighted,
      value: fh.value,
      count: fh.count
    }))

  const handleClick = option => {
    toggleFacet({
      name: facetName,
      value: option.value,
      label: option.value
    })
  }

  const handleChange = (ev) => {
    let { value } = ev.target
    setQuery(value)
    searchFacets(value)
  }

  const handleClearClick = () => {
    setQuery('')
    searchFacets('')
  }

  const renderHTML = (option) => ({__html: option['label']})

  const handleToggleShowAll = () => setShowAll(!showAll)
  const handleToggleExpand = () => {
    if(!expanded) searchFacets(query);
    setExpanded(!expanded)
  }

  const maxFacetValues = 50
  const isLoaded = options && options.length > 0

  return (
    <StyledContainer>
      <StyledList>
        <ListItem button onClick={ handleToggleExpand }>
          <ListItemText primary={
              <Typography variant='overline'>{ label }</Typography>
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={ handleToggleExpand }>
              { expanded ? <ArrowDropUp /> : <ArrowDropDown /> }
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </StyledList>

      { expanded &&
        <StyledBox px={1}>
          <Sticky>
            <Input>
              <TextInput
                value={query}
                placeholder={ `search...`}
                handleChange={ handleChange }
                handleClearClick={handleClearClick}
                margin='dense'
              />
            </Input>
          </Sticky>
          <StyledList>
           {isLoaded && options.map((option, i) => (
             <ListItem
                dense
                disableGutters
                key={i}
                button
                onClick={()=> handleClick(option)}>
                <ListItemIcon>
                  <Checkbox
                    color='primary'
                    value={true}
                    checked={ values.find(v => v.value == option.value) ? true : false }
                  />
                </ListItemIcon>
                <ListItemText
                  secondary={
                      <div dangerouslySetInnerHTML={renderHTML(option)} />
                  }
                />
                <ListItemSecondaryAction>
                  <StyledChip label={option.count} size='small'/>
                </ListItemSecondaryAction>
             </ListItem>
           ))}
           { !isLoaded &&
             <ListItem><ListItemText secondary='No results...' /></ListItem>
           }
        </StyledList>
      </StyledBox>
    }
    <Divider />
  </StyledContainer>
  )
}

export default SelectFacet
