import {
  isEmail,
  isWorkEmail,
  isMinLength,
  notEmpty,
  isEmpty
} from './validate'

export const validateUser = (user) => {
  let isValid = true
  let messages = []

  if(isEmpty(user.firstName) || isEmpty(user.lastName)){
    isValid = false
    messages.push('Full name is required')
  }

  if(isEmpty(user.email)){
    isValid = false
    messages.push('Email is required')
  }

  if(isEmpty(user.password)){
    isValid = false
    messages.push('Password is required')
  }

  if(!isMinLength(user.email, 4)){
    isValid = false
    messages.push('Email must be at least 4 characters')
  }

  if(!isMinLength(user.password, 4)){
    isValid = false
    messages.push('Password must be at least 4 characters')
  }

  if(!isEmail(user.email)){
    isValid = false
    messages.push('Email is not a valid email')
  }

  let validation = {
    isValid,
    messages
  }

  return  validation
}

export const validateUpdateUser = (user) => {
  let isValid = true
  let messages = []

  if(isEmpty(user.email)){
    isValid = false
    messages.push('Email is required')
  }

  if(!isEmail(user.email)){
    isValid = false
    messages.push('Email is not a valid email')
  }

  if(!isMinLength(user.email, 4)){
    isValid = false
    messages.push('Email must be at least 4 characters')
  }

  if(isEmpty(user.firstName) || isEmpty(user.lastName)){
    isValid = false
    messages.push('First and Last name are required')
  }

  if(!isMinLength(user.firstName, 2)){
    isValid = false
    messages.push('First name must be at least 2 characters')
  }

  if(!isMinLength(user.lastName, 2)){
    isValid = false
    messages.push('Last name must be at least 2 characters')
  }

  let validation = {
    isValid,
    messages
  }

  return  validation
}


export const validateCreateUser = (user) => {
  let isValid = true
  let messages = []

  if(isEmpty(user.email)){
    isValid = false
    messages.push('Email is required')
  }

  if(!isEmail(user.email)){
    isValid = false
    messages.push('Email is not a valid email')
  }

  if(!isMinLength(user.email, 4)){
    isValid = false
    messages.push('Email must be at least 4 characters')
  }

  if(!isMinLength(user.email, 4)){
    isValid = false
    messages.push('Email must be at least 4 characters')
  }

  if(!isWorkEmail(user.email)){
    isValid = false
    messages.push('Email must be a company email')
  }

  if(isEmpty(user.firstName) || isEmpty(user.lastName)){
    isValid = false
    messages.push('Name is required')
  }

  if(!isMinLength(user.firstName, 2)){
    isValid = false
    messages.push('First name must be at least 2 characters')
  }

  if(!isMinLength(user.lastName, 2)){
    isValid = false
    messages.push('Last name must be at least 2 characters')
  }

  if(isEmpty(user.password)){
    isValid = false
    messages.push('A password is required to create the user')
  }

  if(!isMinLength(user.password, 4)){
    isValid = false
    messages.push('Password must be at least 4 characters')
  }

  let validation = {
    isValid,
    messages
  }

  return  validation
}

export const validatePassword = (password) => {
  let isValid = true
  let messages = []

  if(isEmpty(password)){
    isValid = false
    messages.push('Password is required')
  }

  if(!isMinLength(password, 4)){
    isValid = false
    messages.push('Password must be at least 4 characters')
  }

  let validation = {
    isValid,
    messages
  }

  return  validation
}

export const validateEmail = (email) => {
  let isValid = true
  let messages = []

  if(isEmpty(email)){
    isValid = false
    messages.push('Email is required')
  }

  if(!isEmail(email)){
    isValid = false
    messages.push('Email is not a valid email')
  }

  let validation = {
    isValid,
    messages
  }

  return  validation
}
