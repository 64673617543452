import React, { Fragment } from 'react'
import {
  Box,
  Avatar,
  Button,
  IconButton,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { indigo } from '@material-ui/core/colors'
import styled from 'styled-components'


const StyledExpansionPanel = styled(ExpansionPanel)`
`

const Faq = ({ faq }) => (
  <StyledExpansionPanel>
    <ExpansionPanelSummary
      expandIcon={<ExpandMore />}
    >
      <Typography variant="subtitle2">
        { faq.question }
      </Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Typography variant='body2'>
        { faq.answer }
      </Typography>
    </ExpansionPanelDetails>
  </StyledExpansionPanel>
)

export default Faq
