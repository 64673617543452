import React from 'react'
import {
  AppBar,
  Paper,
  Toolbar,
  Typography,
  Box
} from '@material-ui/core'
import mobile from 'is-mobile'
import styled from 'styled-components'

const drawerWidth = 250;

const StyledBox = styled(Box)`
  position: absolute;
  top: 0px;
  ${({mobile}) => !mobile ? `left: ${drawerWidth}px;` : 'left: 10px;'}
`

const Subheader = ({
    buttons,
    secondaryButtons,
    text,
    title,
    ...props
  }) => {
  const mobile = mobile()
  return(
      <StyledBox mobile={ mobile }>
        <Toolbar variant="dense">
          <Box display='flex' width='100%' flexDirection="row" justifyContent='space-between'>
            <Box>
              <Box display='flex' width='100%' flexDirection="row" justifyContent='flex-start'>
                <Typography
                  variant="overline"
                  color="inherit">
                  { text || title }
                </Typography>
            </Box>
          </Box>
          <Box>
            { buttons && buttons.map(button => button)}
          </Box>
        </Box>
      </Toolbar>
    </StyledBox>
  )
}

export default Subheader
