import React, { useState } from 'react'
import {
  Typography,
  IconButton,
  Collapse,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction
} from '@material-ui/core'
import {
  ExpandMore,
  ExpandLess,
  KeyboardArrowRight
} from '@material-ui/icons'
import SubdirectoryArrowRight from 'resources/images/icons/subdirectory_arrow_right.svg'
import styled from 'styled-components'

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 25px;
  width: 25px;
`

const CategoryItem = ({
  parent,
  categories,
  handleClick
  }) => {
  const [open, setOpen] = useState(false)

  const handleParentClick = () => {
    handleClick(parent)
    setOpen(!open)
  }

  return(
    <>
    { parent &&
      <>
        <ListItem dense button onClick={() => handleParentClick(parent)}>
            <StyledListItemIcon>
              { categories && categories.length > 0 &&
                <>
                  { open ? <ExpandMore /> : <KeyboardArrowRight /> }
                </>
              }
            </StyledListItemIcon>
          <ListItemText
            primary={
              <Typography variant='body2' color='textSecondary'>
                { parent.name }
              </Typography>
            }
          />
        </ListItem>
        <Collapse in={open} timeout={250}>
          { categories && categories.map((c, j) => (
            <ListItem dense button onClick={() => handleClick(c)} key={j}>
              <ListItemIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant='body2' color='textSecondary'>
                    { c.name }
                  </Typography>}
              />
            </ListItem>
          ))}
        </Collapse>
      </>
      }
    </>
  )
}


export default CategoryItem
