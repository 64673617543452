import React, { useState, useEffect} from 'react'
import {
  Fab,
  Box,
  IconButton,
  Typography,
  Button,
  List,
  Paper,
  SwipeableDrawer,
  Avatar,
  Fade,
  Zoom,
  Slide,
  Backdrop,
  makeStyles
} from '@material-ui/core'
import {
  Chat,
  ChatBubble,
  Textsms,
  ArrowLeft,
  Clear
} from '@material-ui/icons'
import ChatForm from 'components/chat/ChatForm'
import { CSSGradient, Modal } from 'components'
import mobile from 'is-mobile'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  header: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: theme.palette.primary.dark
  },
  text: {
    color: 'white'
  },
  backdrop: {
    zIndex: '1000'
  },
  clear: {
    height: '20px',
    width: '20px',
    color: 'white'
  },
  icon: {
    marginRight: '10px'
  },
  paper: {
    borderRadius: '0 0 4px 4px'
  },
  container: {
    width: '340px',
    position: 'fixed',
    bottom: '20px',
    right: theme.spacing(2),
  },
  fab: {
    zIndex: '2000',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}))

const ChatWidget = ({
    title='Contact us',
    label='Help Desk',
    onSubmit,
    chat,
    open,
    toggleChat,
    handleChange,
    ...props
  }) => {

  const classes = useStyles()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => setVisible(true), 50)
  },[])

  return(
    <>
      <Fade
        in={visible && !open}
        unmountOnExit>
        <Slide
          in={visible && !open}
          direction='up'>
          <Fab
            className={ classes.fab }
            variant='extended'
            color='secondary'
            onClick={ toggleChat }>
            <Textsms className={ classes.icon } />
            { label }
          </Fab>
        </Slide>
      </Fade>
      <Backdrop className={classes.backdrop} open={open}>
        <Fade
          in={open}
          unmountOnExit>
          <Slide
            direction='up'
            in={open}>
          <Box className={ classes.container }>
            <Box className={ classes.header } width='100%'>
              <Box p={1} width='100%' display='flex' justifyContent='space-between'>
                <Box p={1}>
                  <Typography className={ classes.text } variant='h4'>
                    { title }
                  </Typography>
                </Box>
                <Box>
                  <IconButton onClick={ toggleChat }>
                    <Clear className={ classes.clear } />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Paper className={ classes.paper }>
                <Box p={2}>
                  <ChatForm
                    chat={ chat }
                    handleChange={ handleChange }
                  />
                  <Button
                    className={ classes.button }
                    fullWidth
                    size='large'
                    color='secondary'
                    onClick={ onSubmit }
                    variant='contained'>
                    Send Message
                  </Button>
                </Box>
              </Paper>
          </Box>
        </Slide>
        </Fade>
      </Backdrop>
    </>
  )
}

export default ChatWidget
