import React from 'react'
import {
  Typography,
  Grid,
  Avatar,
  Butotn,
  Chip,
  Tag,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core'
import {
  Star,
  LocationOn,
  Bookmark,
  AccountBalance,
  Work
} from '@material-ui/icons'
import { formatCurrency } from 'helpers/utils'
import styled from 'styled-components'
import { indigo } from '@material-ui/core/colors'
import { lightBlue, primaryDark } from 'theme'

const StyledAvatar = styled(Avatar)`
  background-color: ${ lightBlue  };
`

const styles = {
  icon: {
    color: primaryDark
  }
}

const DocumentSummary = ({ document }) => (
  <List>
    <ListItem disableGutters dense={ true }>
      <ListItemIcon>
        <StyledAvatar>
          <LocationOn style={ styles.icon } />
        </StyledAvatar>
      </ListItemIcon>
      <ListItemText primary={ document.location } />
    </ListItem>

    <ListItem disableGutters dense={ true }>
      <ListItemIcon>
        <StyledAvatar>
          <Bookmark style={ styles.icon } />
        </StyledAvatar>
      </ListItemIcon>
      <ListItemText primary={ document.displayCategory } />
    </ListItem>

    {document.salary &&
      <ListItem disableGutters dense={ true }>
        <ListItemIcon>
          <StyledAvatar>
            <AccountBalance style={ styles.icon } />
          </StyledAvatar>
        </ListItemIcon>
        <ListItemText primary={ `${formatCurrency(document.salary)}` } />
      </ListItem>
    }

    {document.clientRate &&
      <ListItem disableGutters dense={ true }>
        <ListItemIcon>
          <StyledAvatar>
            <AccountBalance style={ styles.icon } />
          </StyledAvatar>
        </ListItemIcon>
        <ListItemText primary={ `${formatCurrency(document.clientRate)}/hour` } />
      </ListItem>
    }

    <ListItem disableGutters dense={ true }>
        <ListItemIcon>
          <StyledAvatar>
            <Star style={ styles.icon } />
          </StyledAvatar>
        </ListItemIcon>
      { document.yearsOfExperience &&
        <ListItemText
          primary={ `${document.yearsOfExperience}+ years of experience` }
        />
      }
    </ListItem>
  </List>
)

export default DocumentSummary
