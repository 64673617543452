import React from 'react'
import {
  useMenu
} from 'hooks'
import {
  Badge,
  Box,
  Menu,
  MenuList,
  List,
  Divider
} from '@material-ui/core'
import {
  MenuItem
} from 'components'
import { PersonAdd, Lens } from '@material-ui/icons'
import { green, pink, indigo, purple } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components'

const StyledList = styled(List)`
  width: 150px;
  padding: 0 0 0 0;
`

const AccountMenu = ({
    open,
    anchorEl,
    handleToggleMenu,
    handleClick,
    ...props
  }) => {

  return(
    <Menu
      open={ open }
      onClose={ handleToggleMenu }
      anchorEl={anchorEl}>
      <StyledList>
        <MenuItem
          text='Invite Team'
          handleClick={(e) => handleClick('users')}
          />
        <MenuItem
          text='Subscription'
          handleClick={(e) => handleClick('payments')}
        />
        <MenuItem
          text='My Account'
          handleClick={(e) => handleClick('editUser')}
        />
        <Divider />
        <MenuItem
          text='Log out'
          handleClick={(e) => handleClick('logout') }
        />
      </StyledList>
  </Menu>
  )
}

export default AccountMenu
