import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useLoaders,
  useAlerts
} from 'hooks'

const usePayments = ({ ...props }) => {

  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const addPayment = async (stripeToken) => {
    try{
      showLoading()
      await api.post(`/api/v1/app/payments/add_payment`, {
        stripeToken
      })
      showAlertSuccess('Account successfully updated')
    }catch(e){
      showAlertError('There was an error adding the payment method')
    }
    hideLoading()
  }

  const removePayment = async () => {
    try{
      showLoading()
      await api.post(`/api/v1/app/payments/remove_payment`)
      showAlertSuccess('Payment method successfully removed')
    }catch(e){
      showAlertError('There was an error removing the payment method')
    }
    hideLoading()
  }

  const subscribe = async (stripePlanId) => {    
    console.log("SUB", stripePlanId)
    try{
      showLoading()
      await api.post(`/api/v1/app/payments/subscribe`, {
        payment: {
          stripePlanId: stripePlanId
        }
      })
      showAlertSuccess('Successfully added the subscription plan to your account')
    }catch(e){
      showAlertError('There was an error adding the subscription')
    }
    hideLoading()
  }

  const unsubscribe = async () => {
    try{
      showLoading()
      await api.post(`/api/v1/app/payments/unsubscribe`)
      showAlertSuccess('Subscription has been canceled')
    }catch(e){
      showAlertError('There was an error canceling the subscription')
    }
    hideLoading()
  }


  return {
    addPayment,
    removePayment,
    subscribe,
    unsubscribe
  }
}

export default usePayments
