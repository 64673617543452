import React from 'react'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { lightBlue } from 'theme'

const StyledBox = styled(Box)`
  background-color: ${ lightBlue };
  border-radius: 4px;
`

const BlueBox = (props) => (
  <StyledBox { ...props }>
    { props.children && props.children }
  </StyledBox>
)

export default BlueBox
