import React from 'react'
import {
  useMenu
} from 'hooks'
import {
  Badge,
  Box,
  Menu,
  MenuList,
  MenuItem
} from '@material-ui/core'
import { green, pink, indigo, purple } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  badgeOnline: {
    height: 10,
    width: 10,
    backgroundColor: green[500]
  },
  badgeOffline: {
    height: 10,
    width: 10,
    backgroundColor: purple[500]
  },
  menu: {
    position: 'absolute',
    top: '100px',
    paddingTop: '60px'
  }
}));


const OnlineStatusMenu = ({
    online,
    handleToggleAvailable,
    open,
    anchorEl,
    handleToggleMenu,
    ...props
  }) => {

  const classes = useStyles()
  return(
    <Menu
      classNames={ classes.menu }
      open={ open }
      onClose={ handleToggleMenu }
      anchorEl={anchorEl}>
      <Box my={4} />
      <MenuItem onClick={(e) => online === 'false' && handleToggleAvailable()}>
      <Badge
        variant='dot'
        classes={{ dot: classes.badgeOnline }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Box mx={1} />
        </Badge>
        Available Now
      </MenuItem>
      <MenuItem onClick={(e) => online === 'true' && handleToggleAvailable()}>
      <Badge
        variant='dot'
        classes={{ dot: classes.badgeOffline }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Box mx={1} />
        </Badge>
        Not Available
      </MenuItem>
  </Menu>
  )
}

export default OnlineStatusMenu
