import {
  isEmail,
  isEmpty,
  isFalse,
  isLinkedinUrl
} from './validate'


export const validateDocument = (document) => {
  let isValid = true
  let messages = []

  if(isEmpty(document.categoryId)){
    isValid = false
    messages.push('Please select a category')
  }

  let validation = {
    isValid,
    messages
  }

  return  validation
}

export const validateUpdateDocument = (document) => {
  let isValid = true
  let messages = []

  if(isEmpty(document.title)){
    isValid = false
    messages.push('Title is required')
  }

  if(isEmpty(document.categoryId)){
    isValid = false
    messages.push('Category is required')
  }

  let validation = {
    isValid,
    messages
  }

  return  validation
}


export const validateBulkUpload = (document) => {
  let isValid = true
  let messages = []

  if(isEmpty(document.files)){
    isValid = false
    messages.push('Please select files to upload')
  }

  if(isEmpty(document.categoryId)){
    isValid = false
    messages.push('Please select a category')
  }

  if(isEmpty(document.version)){
    isValid = false
    messages.push('Please select a REVIT version')
  }

  let validation = {
    isValid,
    messages
  }

  return  validation
}
