import React from 'react'
import {
  Box,
  Paper,
  List
} from '@material-ui/core'
import styled from 'styled-components'
import { blue } from '@material-ui/core/colors'

const StyledPaper = styled(Paper)`
  border: 2px solid white;
  ${(({selected}) => selected === "true" && `border: 2px solid ${blue[400]}`)}
`

const ListWrapper = ({ selected, children }) => (
  <Box my={1}>
    <StyledPaper selected={ selected ? 'true' : 'false'} elevation={1}>
      <List>
        { children }
      </List>
    </StyledPaper>
  </Box>
)

export default ListWrapper
