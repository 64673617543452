import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  useResource,
  useNavigation,
  useLoaders,
  useAlerts
} from 'hooks'
import { validateDocument } from 'helpers/validations/document'

const useGuestDocuments = ({ id, ...props }) => {

  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    resourceId: documentId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: document,
    resources: documents,
    fetch: fetchDocument,
    fetchAll: fetchDocuments,
    update: updateDocument,
    create: createDocument,
    save: saveDocument,
    destroy: deleteDocument,
    handleChange,
    handleChangePage,
    refresh: refreshDocument,
    refreshAll: refreshDocuments,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    setResource: setDocument,
    showValidationErrors
  } = useResource({
    id: id,
    url: '/api/v1/documents',
    resourceName: 'document',
    validateFn: validateDocument
  })

  return {
    isLoading,
    isLoaded,
    isValid,
    document,
    fetchDocument,
    refreshDocument
  }
}

export default useGuestDocuments
